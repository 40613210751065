export const formatDateForInput = dateString => {
  const date = new Date(dateString);

  // Adjust the date to local time in the format `YYYY-MM-DDTHH:MM`
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const kalender = process.env.NODE_ENV === 'development';
