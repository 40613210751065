import ReactECharts from 'echarts-for-react';
import { MeridaLogo } from '../../Assets/AllImages';

function DonutChart({ option }) {
  return (
    <div className="relative">
      <div
        className="relative"
        style={{ zIndex: 100 }}
      >
        <ReactECharts
          style={{ width: '100%', height: '150%' }}
          option={option}
          opts={{ renderer: 'svg' }} // Set renderer to SVG
        />
      </div>

      <div className="absolute top-[50%] right-[50%] translate-x-[50%] -translate-y-[50%] p-0 m-0 rounded-full z-10">
        <img
          width={43}
          className="object-cover"
          src={MeridaLogo}
          alt=""
        />
      </div>

      <div className="absolute top-[50%] right-[50%] translate-x-[50%] -translate-y-[50%] p-0 m-0  z-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="175"
          height="172"
          viewBox="0 0 175 172"
          fill="none"
        >
          <path
            d="M174.677 86.2133C174.677 133.48 135.698 171.798 87.6149 171.798C39.5318 171.798 0.552734 133.48 0.552734 86.2133C0.552734 38.9463 39.5318 0.628906 87.6149 0.628906C135.698 0.628906 174.677 38.9463 174.677 86.2133ZM45.3212 86.2133C45.3212 109.175 64.2567 127.789 87.6149 127.789C110.973 127.789 129.909 109.175 129.909 86.2133C129.909 63.2516 110.973 44.6374 87.6149 44.6374C64.2567 44.6374 45.3212 63.2516 45.3212 86.2133Z"
            fill="url(#paint0_linear_331_2629)"
            fillOpacity="0.1"
          />
          <defs>
            <linearGradient
              id="paint0_linear_331_2629"
              x1="158.165"
              y1="116.217"
              x2="29.7301"
              y2="39.0359"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D80032" />
              <stop
                offset="1"
                stopColor="#D80032"
                stopOpacity="0"
              />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default DonutChart;
