import React from 'react';

function TaskListSVG({ active, srcImg }) {
  return (
    <div>
      <img
        width={22}
        height={22}
        src={srcImg}
        alt="Task List"
        style={{
          filter: !active && 'brightness(0) invert(1)', // Applies conditionally
        }}
      />
    </div>
  );
}

export default TaskListSVG;
