import React, { useContext, useEffect, useState } from 'react';
import Loading from './Loading';
import { Store } from '../Context/StateStore';
import axios from 'axios';
import { hostName } from '../App';
import HistoryComponent from './HistoryComponent';

const TableRow = props => {
  const { obj, updateObj, type } = props;
  const [bool, setbool] = useState(false);
  const [id, setId] = useState(-1);
  const {
    getParticulatTaskList,
    particularProjectTask,
    setParticularProjectTask,
  } = useContext(Store);
  // console.log(obj);
  const handleChange = (e, index) => {
    const newValue = e.target.value;
    updateObj(index, newValue);
  };
  let status = JSON.parse(sessionStorage.getItem('status'));
  let [temp, settemp] = useState();
  useEffect(() => {
    if (obj != undefined && obj.length > 0) settemp(obj);
    else settemp([]);
  }, [obj]);
  const changeRemarks = (id, remarks) => {
    axios
      .put(`${hostName}/api/UpdateRemarks/`, { id: id, remarks: remarks })
      .then(response => {
        console.log(response.data);
        getParticulatTaskList(id);
        // alert('Saved the Changes')
      })
      .catch(error => {
        console.log(error);
      });
  };
  let handleSorting = e => {
    let value = e.target.value;
    if (value == '') {
      settemp(obj);
    }
    if (value == 'pi') {
      let temporary = [...obj].sort((a, b) => b.priority - a.priority);
      settemp(temporary);
    }
    if (value == 'du') {
      // alert('hellow')
      let temporary = [...obj].sort((a, b) => {
        const dateA = new Date(a.due_date);
        const dateB = new Date(b.due_date);

        // Compare the dates
        return dateA - dateB;
      });
      settemp(temporary);
    }
  };
  const { apiCallTaskChangesCall, taskloading, setTaskLoading } =
    useContext(Store);
  let handleDueDate = (e, pid) => {
    axios
      .post(
        `${hostName}/api/ChangeSubTaskPriority/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        {
          tid: pid,
          due_date: e.target.value,
        },
      )
      .then(response => {
        console.log(response.data);
        alert('Changed the Due Date');
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <>
      <select
        onChange={handleSorting}
        className="p-2 outline-none ms-auto flex bg-slate-50 rounded  my-3"
        name=""
        id=""
      >
        <option value="">sort</option>
        <option value="pi">Priority H to L</option>
        <option value="du"> Due-Date C to L </option>
      </select>
      <div
        className={` table-responsive w-full border-slate-600 h-[80vh] overflow-scroll table-bordered
     `}
      >
        <table className="w-full border-1 min-w-[700px] ">
          <tr className="">
            <th>
              <p className="px-2 "> SI.No</p>
            </th>
            <th>
              <p className="px-2 w-[150px] "> Task Name</p>
            </th>
            <th>
              <p className="px-2 "> Priority</p>
            </th>
            <th>
              <p className="px-2">Assigned Date </p>
            </th>
            <th>
              <p className="px-2 w100px">Assigned by</p>
            </th>
            <th>
              <p className="px-2 w100px ">Due date</p>
            </th>
            <th>
              <p className="px-2 ">Completed date</p>
            </th>
            <th>
              {' '}
              <p className="px-2 ">Remarks</p>{' '}
            </th>
            <th>
              <p className="px-2 ">Task Status</p>{' '}
            </th>
            {/* <th className={``}><p className="px-2 "> Action </p></th> */}
          </tr>

          {obj == undefined ? (
            <Loading />
          ) : temp != undefined && temp.length > 0 ? (
            temp.map((x, index) => {
              console.log(x);
              return (
                <tr
                  className={`text-sm ${
                    x.index % 2 == 0 ? 'bg-slate-50' : 'bg-slate-100'
                  } h-[50px] `}
                >
                  <td className="px-2">{index + 1} </td>
                  <td className="px-2 max-w-[200px] text-wrap ">
                    {x.task_name}{' '}
                  </td>
                  <td className="text-center px-2">
                    {' '}
                    {status == 'employee' ||
                    x.created_by.employee_id ==
                      JSON.parse(sessionStorage.getItem('dasid'))
                      ? x.priority
                      : ''}
                    {status != 'employee' &&
                    x.created_by.employee_id !=
                      JSON.parse(sessionStorage.getItem('dasid')) ? (
                      <input
                        type="text"
                        value={x.priority}
                        className="outline-none bg-transparent w-20 text-center "
                      />
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="px-2">{x.created_date.slice(0, 10)} </td>
                  <td className="px-2 ">{x.created_by.name}</td>
                  <td className="px-2">
                    {status == 'employee' ||
                    x.created_by.employee_id ==
                      JSON.parse(sessionStorage.getItem('dasid'))
                      ? x.due_date.slice(0, 10)
                      : ''}
                    {status != 'employee' &&
                    x.created_by.employee_id !=
                      JSON.parse(sessionStorage.getItem('dasid')) ? (
                      <input
                        disabled={x.edited_status}
                        onChange={e => {
                          handleDueDate(e, x.id);
                        }}
                        type="date"
                        value={x.due_date.slice(0, 10)}
                        className="outline-none bg-transparent  "
                      />
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="px-2">
                    {x.completed_date ? x.completed_date.slice(0, 10) : '----'}
                  </td>
                  <td
                    className=" "
                    onClick={() => setId(index)}
                  >
                    <textarea
                      disabled={
                        x.created_by.employee_id !=
                        JSON.parse(sessionStorage.getItem('dasid'))
                      }
                      onChange={e => {
                        handleChange(e, index);
                        changeRemarks(x.id, x.remarks);
                      }}
                      name="remarks"
                      id={index}
                      cols="auto"
                      value={x.remarks}
                      rows="auto"
                      className={`${
                        id == index ? 'bg-white' : 'bg-transparent'
                      } max-w-[200px] text-wrap p-2 outline-none `}
                    ></textarea>
                  </td>
                  <td>
                    {taskloading != x.id && (
                      <select
                        onChange={e => {
                          apiCallTaskChangesCall(e, x.id);
                          setTaskLoading(x.id);
                        }}
                        name=""
                        className="outline-none bg-transparent cursor-pointer"
                        id=""
                      >
                        <option
                          value={
                            x.status.slice(0, 3) == 'com'
                              ? 'completed'
                              : 'pending'
                          }
                        >
                          {x.status.slice(0, 3) == 'com'
                            ? 'completed'
                            : 'pending'}
                        </option>
                        <option
                          value={
                            x.status == 'pending' ? 'completed' : 'pending'
                          }
                        >
                          {x.status == 'pending' ? 'completed' : 'pending'}
                        </option>
                      </select>
                    )}
                    {taskloading == x.id && 'loading...'}
                    <HistoryComponent
                      id={x.id}
                      type="task"
                    />
                  </td>
                  {/* <td className={``}>
                        
                        <button onClick={() => {
                          document.getElementById(`${index}`).removeAttribute('disabled')
                        }} className='p-2 rounded-full border-1 mx-1 border-slate-900'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
                          </svg>
                        </button>

                        <button onClick={() => changeRemarks(x.id, x.remarks)} className='p-2 rounded-full border-1 mx-1 border-slate-900'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-bookmark" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 8V1h1v6.117L8.743 6.07a.5.5 0 0 1 .514 0L11 7.117V1h1v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8" />
                            <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                            <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                          </svg>
                        </button>

                      </td> */}
                  {/* Add more data cells as needed */}
                </tr>
              );
            })
          ) : (
            <div className="text-center h-[40px] text-uppercase absolute ">
              no task is assigned yet
            </div>
          )}
        </table>
      </div>
    </>
  );
};

export default TableRow;
