import React, { useEffect, useState } from 'react';
import ProjectTable from '../Component/ProjectTable';
import axios from 'axios';
import { hostName } from '../App';

const PendingTaskMain = () => {
  const [data, setdata] = useState();
  useEffect(() => {
    axios
      .get(
        `${hostName}/api/PendingProjects/pending/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
      )
      .then(response => {
        setdata(response.data);
        console.log(response.data, 'hellow');
      })
      .catch(error => {
        console.log(error, 'hellow');
      });
  }, []);
  return (
    <div>
      <div className="my-2 ">
        <h4>Pending Tasks</h4>
      </div>
      {data != undefined ? <ProjectTable data={data} /> : ''}
    </div>
  );
};
export default PendingTaskMain;
