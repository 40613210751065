import axios from 'axios';
import { useEffect, useState } from 'react';
import { hostName } from '../../../App';

function FilledStatus({ active, setActive, setStatus, status }) {
  const [dasData, setDasData] = useState();

  const today = new Date();

  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();

  console.log('MUIZ', todayDate, todayMonth, todayYear);

  const getDASFilled = () => {
    axios
      .get(
        `${hostName}/api/FilledDASRecords/${todayYear}/${todayMonth}/${todayDate}`,
      )
      .then(res => {
        console.log('DAS FILLED', res.data.employees);
        setDasData(res.data.employees);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getDASFilled();
  }, []);

  const filledEmployees = dasData?.filter(each => each.status === 'filled');
  const NotFilledEmployees = dasData?.filter(
    each => each.status === 'not filled',
  );

  const detailsElement = document.getElementById('details');

  return (
    <div
      className={`${
        active === 'filledStatus' ? 'bg-[#6B6DD8]' : 'bg-[#EBEDFD] '
      } px-3 gap-x-5 flex w-[205px] py-2 rounded-xl font-sans  cursor-pointer`}
      onClick={() => {
        detailsElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setActive('filledStatus');
      }}
    >
      <div className="flex flex-col items-center justify-center bg-white  rounded-full w-[76px] h-[76px] my-auto flex-shrink-0 ">
        <p
          className={`m-[1px] text-green-600 ${
            status === 'filled' ? 'bg-zinc-300' : ''
          } rounded-xl px-[5px] hover:bg-zinc-200 text-xl bg-green-30 `}
          onClick={() => {
            setStatus('filled');
          }}
        >
          {filledEmployees?.length < 10 ? 0 : ''}
          {filledEmployees?.length}
        </p>
        <p className="w-[80%] bg-slate-400 h-[1px] m-0 p-[1px] my-[2px]"></p>
        <p
          className={`m-[1px] hover:bg-zinc-200 text-red-500 rounded-xl px-[5px] text-xl ${
            status === 'not filled' ? 'bg-zinc-300' : ''
          }`}
          onClick={() => {
            setStatus('not filled');
          }}
        >
          {NotFilledEmployees?.length < 10 ? 0 : ''}
          {NotFilledEmployees?.length}
        </p>
      </div>
      <div className="flex py-1 flex-col justify-center">
        <p
          className={`mt mb-0 capitalize ${
            active === 'filledStatus' ? 'text-white' : 'text-[#4F6DCC]'
          } text-2xl font-semibold `}
        >
          {'DAS '}
        </p>
      </div>
    </div>
  );
}

export default FilledStatus;
