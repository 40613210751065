import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { hostName } from '../App';
import StateStore, { Store } from '../Context/StateStore';
import ResponsiveTimePickers from './TimePicker';
import PlusIcon from '../SVG/PlusIcon';
import TextIcon from '../SVG/TextIcon';
import TimePicker from './TimePicker';
import { ClockIcon } from '@mui/x-date-pickers';
import ArrowRghtIconSVG from '../SVG/ArrowRghtIconSVG';
import TasDetails from './TAS/TasDetails';
import { FiPlus } from 'react-icons/fi';
import TASEdit from './TAS/TASEdit';
import { kalender } from '../utils/DateTime/timeFormatter';

const DepDas = props => {
  const { dasSheet, updateObj, todayDate, emailId } = props;

  const [id, setid] = useState();

  const { getDas, setdas, getCurrentDate, calenderDate } = useContext(Store);

  const [show, setshow] = useState(false);

  let email = JSON.parse(sessionStorage.getItem('email'));

  const [obj, setobj] = useState({
    action_planned: '',
    action_acheived: 'pending',
    remarks: '',
    remarks2: '',
    assigned_time: '',
    estimated_completed_time: '',
  });
  function formatTimeTo12HourFormat(time24) {
    var timeSplit = time24.split(':');
    var hour = parseInt(timeSplit[0], 10);
    var minute = timeSplit[1];
    var amPM = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12; // If hour is 0, make it 12
    return hour + ':' + minute + ' ' + amPM;
  }
  let validateEndingTime = (startTime, endTime) => {
    // Split the time strings into hours and minutes
    let [startHour, startMinute] = startTime.split(':').map(Number);
    let [endHour, endMinute] = endTime.split(':').map(Number);

    // Create Date objects for the start and end times
    let now = new Date(); // Use current date for reference
    let startDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      startHour,
      startMinute,
    );
    let endDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      endHour,
      endMinute,
    );

    // Calculate the time difference in milliseconds
    let timeDiff = endDate - startDate;

    // Convert time difference from milliseconds to hours
    let hoursDiff = timeDiff / (1000 * 60 * 60);

    if (hoursDiff <= 1) {
      return endTime; // Valid time difference
    } else {
      // Adjust the end time to be exactly 1 hour from the start time
      endDate = new Date(startDate.getTime() + 1 * 60 * 60 * 1000);

      // Extract hours and minutes from the adjusted end time
      let adjustedEndHour = endDate.getHours().toString().padStart(2, '0');
      let adjustedEndMinute = endDate.getMinutes().toString().padStart(2, '0');

      // Format the adjusted end time back to "HH:MM"
      return `${adjustedEndHour}:${adjustedEndMinute}`;
    }
  };

  const handleCHange = e => {
    let { name, value } = e.target;
    if (name == 'estimated_completed_time' || name == 'assigned_time') {
      if (name == 'estimated_completed_time' && obj.assigned_time) {
        if (value <= obj.assigned_time) {
          //  alert(obj.assigned_time)
          value = obj.assigned_time;
        } else {
          value = validateEndingTime(obj.assigned_time, value);
        }
      }
      //  value=formatTimeTo12HourFormat(value)
    }
    setobj(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    updateObj(index, newValue);
  };
  const changeRemarks = (id, remarks) => {
    axios
      .patch(
        `${hostName}/api/DASView/${JSON.parse(
          sessionStorage.getItem('email'),
        )}/`,
        { id: id, remarks2: remarks },
      )
      .then(response => {
        console.log(response.data);
        console.log(email);
        // getDas(calenderDate, email)
        // alert('Action has been changed')
      })
      .catch(error => {
        console.log(error);
      });
  };
  let [errorMessage, seterrorMessage] = useState();

  let [submitLoading, setSubmitLoading] = useState(false);
  let handleSubmit = () => {
    console.log(obj, calenderDate);
    if (
      obj.action_planned != '' &&
      obj.assigned_time != null &&
      obj.estimated_completed_time != null
    ) {
      // validateTime()
      setSubmitLoading(true);
      axios
        .post(
          `${hostName}/api/DASView/${JSON.parse(
            sessionStorage.getItem('email'),
          )}/`,
          {
            ...obj,
            remarks2: obj.remarks,
            assigned_time: formatTimeTo12HourFormat(obj.assigned_time),
            estimated_completed_time: formatTimeTo12HourFormat(
              obj.estimated_completed_time,
            ),
            date: calenderDate,
          },
        )
        .then(response => {
          setshow(false);
          getDas(calenderDate, email);
          console.log(response.data);
          setobj({
            action_planned: '',
            action_acheived: 'pending',
            assigned_time: '',
            estimated_completed_time: '',
          });
          alert('Das Task has been added');
          setSubmitLoading(false);
        })
        .catch(error => {
          console.log(error);
          setSubmitLoading(false);
        });
    } else {
      seterrorMessage('* Add the Required fields');
    }
  };
  let change_status = (id, status) => {
    setid(-1);
    axios
      .patch(
        `${hostName}/api/DASView/${JSON.parse(
          sessionStorage.getItem('email'),
        )}/`,
        { id: id, action_acheived: status },
      )
      .then(response => {
        console.log(response.data);
        console.log(email);

        getDas(calenderDate, email);
        alert(`Your changed to ${status}`);
      })
      .catch(error => {
        console.log(error);
      });
  };
  let dateEODTime = inputTime => {
    // Parse the input time and AM/PM part
    let [time, modifier] = inputTime.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    // Convert 12-hour format to 24-hour format
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    // Create a new Date object and set the time
    let date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    // Add 9 hours
    date.setHours(date.getHours() + 9);

    // Format the new time back to 12-hour format
    let newHours = date.getHours();
    let newModifier = 'AM';

    if (newHours >= 12) {
      newModifier = 'PM';
      if (newHours > 12) {
        newHours -= 12;
      }
    } else if (newHours === 0) {
      newHours = 12;
    }

    let newMinutes = date.getMinutes().toString().padStart(2, '0');
    let formattedTime = `${newHours}:${newMinutes} ${newModifier}`;

    return formattedTime;
  };

  return (
    <div>
      {/* Table */}
      <main className="row min-h-[55vh] ">
        <section className="col-md-6 p-3 ">
          <div className=" p-2 dascolbg w-full h-full rounded-lg border-dashed border-slate-400 border-2 ">
            <article className="text-xs my-2 items-center flex justify-between relative">
              <p className="m-0">
                Action Planned ({dasSheet && dasSheet.length}){' '}
              </p>
              {/* {dasSheet && dasSheet.length > 0 && (
                <p className="m-0 text-red-600">
                  {' '}
                  You have started the day at {dasSheet[0].assigned_time} , try
                  to fill DAS till {dateEODTime(dasSheet[0].assigned_time)}{' '}
                </p>
              )} */}
              {getCurrentDate() <= calenderDate && !emailId && (
                <button
                  onClick={() => setshow(true)}
                  className="flex text-white absolute p-1 -top-[52px] rounded-md right-0 bg-[#6B6DD8] hover:bg-[#8183E6] "
                >
                  <FiPlus className="text-lg" />
                  <span className="px-1">New Task </span>
                </button>
              )}
            </article>
            {/* Cards */}
            {dasSheet?.map((obj, index) => {
              let c = getCurrentDate();
              return (
                <TasDetails
                  calenderDate={calenderDate}
                  change_status={change_status}
                  emailId={emailId}
                  getCurrentDate={getCurrentDate}
                  obj={obj}
                  key={index}
                />
              );
            })}
          </div>
          {getCurrentDate() <= calenderDate && !emailId && (
            <button
              onClick={() => setshow(true)}
              className="flex text-white mt-2 p-1 items-center justify-center rounded-md bg-[#6B6DD8] hover:bg-[#8183E6] "
            >
              <FiPlus className="text-md" />
              <span className="px-1">New Task </span>
            </button>
          )}

          {kalender && !emailId && (
            <button
              onClick={() => setshow(true)}
              className="flex text-white absolute -bottom-[77px] left-[19%]  p-1 items-center justify-center rounded-md  "
            >
              .
            </button>
          )}
        </section>
        <section className="col-md-6 p-3 ">
          <div className="dascolbg w-full h-full p-2 rounded-lg border-dashed border-slate-400 border-2 ">
            <article className="text-xs my-2 items-center flex justify-between">
              <p className="m-0">
                Action Acheived (
                {dasSheet &&
                  dasSheet.filter(obj => obj.action_acheived === 'completed')
                    .length}
                ){' '}
              </p>
            </article>
            {dasSheet != undefined &&
              dasSheet
                .filter(obj => obj.action_acheived === 'completed')
                .map((obj, index) => {
                  let c = getCurrentDate();
                  console.log(obj);
                  return (
                    <>
                      <TASEdit
                        calenderDate={calenderDate}
                        emailId={emailId}
                        getCurrentDate={getCurrentDate}
                        handleChange={handleChange}
                        obj={obj}
                      />
                    </>
                  );
                })}
          </div>
        </section>
      </main>

      {show && (
        <Modal
          show={show}
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <p className="text-center w-fit mx-auto flex mb-0"> Add Task</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-slate-500 justify-around gap-3">
            <div className="flex items-center border-y-2 w-full">
              <TextIcon /> :
              <input
                type="text"
                onChange={handleCHange}
                name="action_planned"
                className="p-2 px-3 w-full   my-1 block outline-none bg-transparent rounded "
                placeholder="Action planned"
              />
            </div>
            <article className="flex justify-between border-b-2 my-2">
              <div>
                <label htmlFor="startTimemodel"> Start Time</label>
                <input
                  id="startTimemodel"
                  type="time"
                  value={obj.assigned_time}
                  onChange={handleCHange}
                  name="assigned_time"
                  className="p-2 px-3 my-1 border-0 outline-none bg-transparent rounded "
                  placeholder="12 : 30"
                />
              </div>
              <div>
                End Time
                <input
                  type="time"
                  value={obj.estimated_completed_time}
                  onChange={handleCHange}
                  name="estimated_completed_time"
                  className="p-2 px-3 my-1 outline-none bg-transparent rounded "
                  placeholder="1 : 30"
                />
              </div>
            </article>
            <div className="">
              <p>Add notes</p>
              <textarea
                rows={6}
                className="block p-2 outline-none w-full border-2 rounded-lg  "
                name="remarks"
                value={obj.remarks}
                onChange={handleCHange}
                id=""
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <p className="text-red-600 h-[30px] ">{errorMessage} </p>
            <button
              disabled={submitLoading}
              onClick={handleSubmit}
              className="p-2 w-32 px-3 bg-green-600 text-white rounded"
            >
              {submitLoading ? 'Loading...' : 'Add'}
            </button>

            <button
              onClick={() => {
                setshow(false);
                setobj({
                  action_planned: '',
                  action_acheived: 'pending',
                  assigned_time: '',
                  estimated_completed_time: '',
                });
              }}
              className="p-2 px-3 bg-red-600 text-white rounded"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default DepDas;
