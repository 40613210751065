function StarSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
    >
      <path
        d="M7.28048 1.9118C7.35384 1.78591 7.45893 1.68145 7.58527 1.60885C7.7116 1.53625 7.85477 1.49805 8.00048 1.49805C8.14619 1.49805 8.28935 1.53625 8.41569 1.60885C8.54202 1.68145 8.64711 1.78591 8.72048 1.9118L10.5838 5.11047L14.2025 5.89447C14.3448 5.9254 14.4766 5.9931 14.5846 6.09083C14.6926 6.18855 14.7731 6.31288 14.8181 6.45142C14.8631 6.58996 14.871 6.73788 14.841 6.88042C14.811 7.02296 14.7442 7.15515 14.6471 7.2638L12.1805 10.0245L12.5538 13.7078C12.5685 13.8528 12.545 13.9992 12.4854 14.1323C12.4259 14.2654 12.3325 14.3805 12.2146 14.4662C12.0966 14.5519 11.9583 14.6051 11.8133 14.6206C11.6684 14.6361 11.5219 14.6133 11.3885 14.5545L8.00048 13.0611L4.61248 14.5545C4.47909 14.6133 4.33261 14.6361 4.18765 14.6206C4.04269 14.6051 3.90433 14.5519 3.78639 14.4662C3.66845 14.3805 3.57505 14.2654 3.51552 14.1323C3.45599 13.9992 3.43242 13.8528 3.44715 13.7078L3.82048 10.0245L1.35381 7.26447C1.25663 7.15582 1.18965 7.02358 1.15955 6.88095C1.12946 6.73832 1.13731 6.59029 1.18231 6.45164C1.22731 6.31299 1.30789 6.18857 1.41602 6.0908C1.52414 5.99303 1.65601 5.92534 1.79848 5.89447L5.41715 5.11047L7.28048 1.9118Z"
        fill="white"
      />
    </svg>
  );
}

export default StarSVG;
