function HomeSVG({ active }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8167 20.5H12.3C11.9228 20.5 11.6167 20.1939 11.6167 19.8167V14.35H8.88334V19.8167C8.88334 20.1939 8.5772 20.5 8.2 20.5H0.683333C0.306133 20.5 0 20.1939 0 19.8167V6.83333C0 6.601 0.118217 6.38438 0.31365 6.25865L9.88032 0.10865C10.1058 -0.0362167 10.3942 -0.0362167 10.6197 0.10865L20.1864 6.25865C20.3818 6.38438 20.5 6.601 20.5 6.83333V19.8167C20.5 20.1939 20.1939 20.5 19.8167 20.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default HomeSVG;
