import { useContext } from 'react';
import { DeskGuy } from '../../../Assets/AllImages';
import GuyBGM from '../../../Assets/SVG/GuyBGM.svg';
import GUY from './GuyWithDesk.png';
import { Store } from '../../../Context/StateStore';
import { useNavigate } from 'react-router-dom';

function HelloUser({ pendingData, overDueData }) {
  const { user } = useContext(Store);

  const navigate = useNavigate();

  const pendingTasksNos = pendingData?.length;
  const overDueTasksNos = overDueData?.length;

  console.log(user);
  return (
    <div className="bg-[#6C6ED8] rounded-3xl flex h-max w-full border-black relative">
      <div className="px-7 py-6 text-white ">
        <h1 className="text-lg leading-5 capitalize">{`Hello ${
          user.gender === 'male' ? 'Mr' : 'Ms'
        } ${user?.full_name} `}</h1>
        <div className="w-[80%] text-sm mt-8">
          <span>{`You’ve ${pendingTasksNos} Pending Tasks! `}</span>
          <span className="ml-2">{`You’ve ${overDueTasksNos} Over Due Tasks! `}</span>
        </div>
        <button
          onClick={() => navigate('/dashboard/tasklist')}
          className="bg-white mt-14 rounded-[5px] px-[18px] font-bold py-2 text-[#6C6ED8]"
        >
          Check Here
        </button>
      </div>

      <div className=" w-[320px] ">
        <img
          src={GUY}
          alt="Character with Desk"
          className="w-full h-full object-fit rounded-r-3xl"
        />
      </div>
    </div>
  );
}

export default HelloUser;
