import { Store } from '../../Context/StateStore';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { hostName } from '../../App';
import React, { useContext, useEffect, useState } from 'react';
import { json, useNavigate } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CalenderView from '../../SVG/CalenderView';
import Das from '../Das';
import ProfileIcon from '../../SVG/ProfileIcon';
import MessageIcon from '../../SVG/MessageIcon';
import DoughnutAnalysis from './DoughnutAnalysis';
import BasketIcon from '../../SVG/BasketIcon';
import EmployeeDetailsUpdate from './EmployeeDetailsUpdate';
import EmployeeAnalytics from '../../Component/Analytics/EmployeeAnalytics';

const ParticularEmployee = () => {
  let { id } = useParams();
  let [employee, setEmployee] = useState();
  let [userId, setUserId] = useState(
    JSON.parse(sessionStorage.getItem('dasid')),
  );
  let status = JSON.parse(sessionStorage.getItem('status'));
  let navigate = useNavigate();
  let [strategyData, setStrategyData] = useState();
  let [apicall, setapicall] = useState(true);
  let { setactive, getCurrentDate } = useContext(Store);
  let [showoperations, setShowoperations] = useState(false);
  let [selectedEmp, setSelectedEmp] = useState({
    name: '',
    email: '',
    blocked_status: null,
    employee_id: null,
    des: '',
  });
  let [show, setshow] = useState(false);
  let [task, settask] = useState({
    task_name: '',
    priority: null,
    remarks: '',
    due_date: '',
    created_by: JSON.parse(sessionStorage.getItem('dasid')),
  });
  let handleChange = e => {
    let { name, value } = e.target;
    if (name === 'priority' && Number(value) > 10) {
      value = 10;
    }
    if (name === 'priority' && Number(value) < 0) {
      value = '';
    }
    if (name === 'due_date' && value < getCurrentDate()) {
      value = getCurrentDate();
    }
    settask(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  let [showDas, setShowDas] = useState(false);
  let [countDetails, setCountDetails] = useState();

  let blockEmplye = id => {
    axios
      .post(
        `${hostName}/api2/BlockUser/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        {
          empid: selectedEmp.employee_id,
        },
      )
      .then(response => {
        console.log(response.data);
        setShowoperations(false);
        toast.error(`Emplyoee has been ${response.data}`, {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored',
        });
        getParticularEmp();
      })
      .catch(error => {
        console.log(error);
      });
  };
  let submitTask = () => {
    axios
      .post(
        `${hostName}/api2/CreateProjectAssignProject/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        {
          empid: selectedEmp.employee_id,
          ...task,
        },
      )
      .then(response => {
        console.log(response.data);
        setshow(false);
        toast.success(`Project has been added to ${selectedEmp.name}`, {
          autoClose: 3000,
          theme: 'colored',
          position: 'top-center',
        });
        getStrategy();
      })
      .catch(error => {
        console.log(error);
      });
  };
  let getStrategy = async () => {
    if (id) {
      await axios
        .get(`${hostName}/api/CountsOfProjects/${id}`)
        .then(response => {
          console.log(response.data);
          setStrategyData(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (selectedEmp) {
      console.log(selectedEmp);
    }
  }, [selectedEmp]);
  let getParticularEmp = () => {
    if (id) {
      axios
        .get(`${hostName}/api/EmployeeDetailsL/${id}`)
        .then(response => {
          setEmployee(response.data);
          setSelectedEmp(response.data);
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    setactive('employee');
    getParticularEmp();
    getStrategy();
    if (id) {
      sessionStorage.setItem('selectedEmp', id);
    }
  }, [id]);
  return (
    <div className="min-h-[89vh]">
      {employee && selectedEmp && strategyData && (
        <main className="row container mx-auto my-2">
          <section className="col-md-8 col-xl-9">
            {/* Cards */}
            <article className="flex flex-wrap gap-3 justify-around sm:justify-between">
              <div
                onClick={() => {
                  setShowDas(true);
                  setShowoperations(false);
                }}
                className="w-[10rem] sm:w-[16rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 "
              >
                <h6 className="text-lg m-auto flex"> Show Das</h6>
                <div className=" flex gap-3 my-3     w-fit m-auto">
                  <p className="mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ">
                    <BasketIcon />{' '}
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setshow(true);
                  setShowoperations(false);
                }}
                className="w-[10rem] sm:w-[16rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 "
              >
                <h6 className="text-lg"> Create Task </h6>
                <div className=" flex gap-3 my-3     w-fit m-auto">
                  <p className="mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ">
                    <BasketIcon />{' '}
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate(`/dashboard/pendingTask/${selectedEmp.employee_id}`);
                }}
                className="w-[10rem] sm:w-[16rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 "
              >
                <h6 className="text-lg"> Pending Task</h6>
                <div className=" flex gap-3 my-3     w-fit m-auto">
                  <p className="mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ">
                    <BasketIcon />{' '}
                  </p>
                  <h6 className="text-2xl sm:text-5xl text-center">
                    {' '}
                    {strategyData.count.pending_tasks}{' '}
                  </h6>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate(`/dashboard/overdue/${selectedEmp.employee_id}`);
                }}
                className="w-[10rem] sm:w-[16rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 "
              >
                <h6 className="text-lg">Overdue Task </h6>
                <div className=" flex gap-3 my-3     w-fit m-auto">
                  <p className="mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ">
                    <BasketIcon />{' '}
                  </p>
                  <h6 className="text-2xl sm:text-5xl text-center">
                    {' '}
                    {strategyData.count.over_due}{' '}
                  </h6>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate(
                    `/dashboard/completedOntime/${selectedEmp.employee_id}`,
                  );
                }}
                className="w-[10rem] sm:w-[16rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 "
              >
                <h6 className="text-lg"> Completed onTime Task</h6>
                <div className=" flex gap-3 my-3     w-fit m-auto">
                  <p className="mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ">
                    <BasketIcon />{' '}
                  </p>
                  <h6 className="text-2xl sm:text-5xl text-center">
                    {' '}
                    {strategyData.count.completed_on_time}{' '}
                  </h6>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate(
                    `/dashboard/completedAfterTime/${selectedEmp.employee_id}`,
                  );
                }}
                className="w-[10rem] sm:w-[16rem]  min-h-[8rem] rounded bg-pink-50 bg-opacity-60 p-2 "
              >
                <h6 className="text-lg"> Completed AfterTime task </h6>
                <div className=" flex gap-3 my-3     w-fit m-auto">
                  <p className="mb-0 rounded-full bg-pink-300 w-12 h-12 flex items-center justify-center ">
                    <BasketIcon />{' '}
                  </p>
                  <h6 className="text-2xl sm:text-5xl text-center">
                    {' '}
                    {strategyData.count.completed_after_time}{' '}
                  </h6>
                </div>
              </div>
            </article>
          </section>
          <section className="col-md-4 col-xl-3">
            {/* profile card */}
            <div className="rounded border-pink-100 dascolbg pt-3 relative  text-center ">
              <img
                src={require('../../Assests/favicon.ico')}
                alt="Logo"
                className="absolute top-3 left-3 w-7 rounded-full h-7 object-contain border-2  "
              />
              <div className="w-fit mx-auto p-1 my-2 border-violet-600 border-e-4 border-4 rounded-full border-spacing-4  ">
                <img
                  src={
                    employee
                      ? employee.profile_pic
                      : require('../../Assests/profilepic.webp')
                  }
                  className="w-24 h-24 border-2 object-cover border-slate-900 rounded-full mx-auto  "
                  alt="Profile pic"
                />
              </div>
              <p className="text-xl mb-1 fw-semibold">{employee.name} </p>
              <p className="mb-1"> {employee.position.position} </p>
              <a
                href={`mailto:${employee.email}`}
                target="_blank"
                className="text-decoration-none  "
                rel="noreferrer"
              >
                <p className=" w-100 break-words ">{employee.email}</p>{' '}
              </a>
              <div className="mx-auto my-2 w-fit flex items-center">
                {!employee.blocked_status && (
                  <button
                    className="bg-indigo-900 p-1 px-2 rounded text-white flex gap-2 items-center "
                    onClick={() =>
                      navigate(`/dashboard/chat/${employee.employee_id}`)
                    }
                  >
                    <MessageIcon /> Chat
                  </button>
                )}
                {status == 'admin' && (
                  <button
                    onClick={() => blockEmplye()}
                    className="bg-red-600 mx-2 p-1 px-2 rounded text-white flex gap-2 items-center "
                  >
                    {employee.blocked_status ? 'Unblock' : 'Block'}
                  </button>
                )}
              </div>

              <img
                className="w-full"
                src={require('../../Assests/Rectangle.png')}
                alt="Rectangle"
              />
            </div>
          </section>
        </main>
      )}
      {strategyData && (
        <main className="row container my-4 mx-auto d-flex">
          <section className="col-md-8 p-3 pt-0 col-xl-9">
            {status === 'admin' && (
              <EmployeeDetailsUpdate
                fun={getParticularEmp}
                data={employee}
              />
            )}
          </section>

          <section className="col-md-4 pt-3 col-xl-3 rounded mb-3 mx-auto bg-white py-2 d-flex flex-column">
            <div className="flex flex-col h-100 gap-y-6">
              <h6 className="text-xl">Analytics</h6>
              <EmployeeAnalytics />
            </div>
          </section>
        </main>
      )}
      {/* old */}
      {showDas && (
        <Modal
          show={showDas}
          onHide={() => setShowDas(false)}
          centered
          size="xl"
        >
          <Modal.Header closeButton></Modal.Header>
          <Das
            empDes={selectedEmp.position.position}
            emailId={selectedEmp.email}
            empdepartment={employee.department && employee.department.id}
            empName={selectedEmp.name}
            employID={selectedEmp.employee_id}
          />
        </Modal>
      )}
      {showoperations && selectedEmp && (
        <Modal
          show={showoperations}
          centered
          size="xl"
          onHide={() => {
            setShowoperations(false);
          }}
        >
          <Modal.Header closeButton>
            Action on the {selectedEmp.name}
          </Modal.Header>
          <Modal.Body>
            <div className="p-3 flex gap-3 justify-around  flex-wrap">
              <section className="flex  items-center flex-col">
                <button
                  onClick={() => {
                    setShowDas(true);
                    setShowoperations(false);
                  }}
                  className="w-fit h-fit text-center p-4 bg-slate-100 rounded-full"
                >
                  <CalenderView />
                </button>
                <p className="w-36 text-center ">Show Das</p>
              </section>
              <section className="flex items-center flex-col">
                <button
                  onClick={() => {
                    setshow(true);
                    setShowoperations(false);
                  }}
                  className="w-fit h-fit text-center p-4 bg-blue-100 rounded-full"
                >
                  <CalenderView />
                </button>
                <p className="w-36 text-center"> Create Task </p>
              </section>
              <section className="flex items-center flex-col">
                <button
                  onClick={() => {
                    navigate(
                      `/dashboard/pendingTask/${selectedEmp.employee_id}`,
                    );
                  }}
                  className="w-fit h-fit text-center p-4 bg-yellow-100 rounded-full"
                >
                  <CalenderView />
                </button>
                <p className="w-36 text-center">View Pending Task</p>
              </section>
              <section className="flex items-center flex-col">
                <button
                  onClick={() => {
                    navigate(`/dashboard/overdue/${selectedEmp.employee_id}`);
                  }}
                  className="w-fit h-fit text-center p-4 bg-orange-100 rounded-full"
                >
                  <CalenderView />
                </button>
                <p className="w-36 text-center">View Overdue Task</p>
              </section>
              <section className="flex items-center flex-col">
                <button
                  onClick={() => {
                    navigate(
                      `/dashboard/completedOntime/${selectedEmp.employee_id}`,
                    );
                  }}
                  className="w-fit h-fit text-center p-4 bg-green-100 rounded-full"
                >
                  <CalenderView />
                </button>
                <p className="w-36 text-center">View Completed OnTime Task</p>
              </section>
              <section className="flex items-center flex-col">
                <button
                  onClick={() => {
                    navigate(
                      `/dashboard/completedAfterTime/${selectedEmp.employee_id}`,
                    );
                  }}
                  className="w-fit h-fit text-center p-4 bg-amber-100 rounded-full"
                >
                  <CalenderView />
                </button>
                <p className="w-36 text-center">View Completed After Task</p>
              </section>
              <section className="flex items-center flex-col">
                <button
                  onClick={() => blockEmplye()}
                  className="w-fit h-fit text-center p-4 bg-red-100 rounded-full"
                >
                  <ProfileIcon />
                </button>
                <p className="w-36 text-center">
                  {' '}
                  {selectedEmp.blocked_status
                    ? 'Unblock'
                    : 'Block'} Employee{' '}
                </p>
              </section>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {show && (
        <Modal
          centered
          className=""
          show={show}
          onHide={() => setshow(false)}
        >
          <Modal.Header
            className="p-3 "
            closeButton
          >
            Adding the Task to {selectedEmp.name}
          </Modal.Header>
          <Modal.Body className="p-3">
            <form
              action=""
              className="flex justify-between flex-wrap"
            >
              <div className="sm:w-[45%] ">
                Task Name :
                <input
                  type="text"
                  onChange={handleChange}
                  name="task_name"
                  className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                  placeholder="Enter the task name "
                />
              </div>
              <div className="sm:w-[45%] ">
                Priority :
                <input
                  type="number"
                  value={task.priority}
                  onChange={handleChange}
                  name="priority"
                  className=" border-1 p-2 block my-2 rounded w-32 nospin bg-slate-50 outline-none"
                  placeholder="Enter in 1- 10 "
                />
              </div>
              <div className="sm:w-[45%] ">
                Remarks :
                <textarea
                  type="text"
                  onChange={handleChange}
                  name="remarks"
                  className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                  placeholder="Enter the remarks "
                />
              </div>
              <div className="sm:w-[45%] ">
                Due Date :
                <input
                  type="date"
                  value={task.due_date}
                  onChange={handleChange}
                  name="due_date"
                  className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                  placeholder="Enter the task name "
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={submitTask}
              className="p-2 px-3 bg-green-600 text-white rounded"
            >
              Create
            </button>
            <button
              onClick={() => setshow(false)}
              className="p-2 px-3 bg-red-600 text-white rounded"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ParticularEmployee;
