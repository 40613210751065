import React, { useContext, useEffect, useState } from 'react';
import ProjectPad from '../SVG/ProjectPad';
import { Store } from '../Context/StateStore';
import { hostName } from '../App';
import axios from 'axios';
import ProjectList from './AdminDas2/ProjectList';
import Members from './AdminDas2/Members';
import PendingList from './AdminDas2/PendingList';
import {
  CompletedLogo,
  EmployeeLogo,
  OverdueLogo,
  PendingLogo,
  ProjectsLogo,
  StagesLogo,
  TasksLogo,
} from '../Assets/AllImages';
import StatsCard from './AdminDas2/Cards/StatsCard';
import StickyNotes1 from './StickyNotes1';
import Chart from './ChartBar';
import FilledStatus from './AdminDas2/Cards/FilledStatus';
import EmployeeTasksAnalysis from './AdminDas2/Analytics/EmployeeTasksAnalysis';
import EmployeeAnalytics from './Analytics/EmployeeAnalytics';
import AllProjects from './AdminDas2/Projects/AllProjects';
import AdminDas3 from './AdminDas3';

const ADminDas2 = () => {
  let { setactive, allDetails, apicall, getCurrentDate } = useContext(Store);
  let [allEmpytask, setAllEmpTask] = useState();
  let [allEmpy, setAllEmpy] = useState();
  let [pendingTask, setPendingTask] = useState();
  let [overdueData, setOverDueData] = useState();

  let getallTask = () => {
    axios
      .get(`${hostName}/api/all_tasks/`)
      .then(response => {
        console.log('ADMIN ALL TASKS', response.data[0]);
        setAllEmpTask(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  let getPendingProjects = () => {
    const PENDINGAPI = `${hostName}/api/CreatedProjectsView/${JSON.parse(
      sessionStorage.getItem('dasid'),
    )}/pending/`;
    axios
      .get(
        `${hostName}/api/CreatedProjectsView/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/pending/`,
      )
      .then(response => {
        console.log('ADMIN ALL PENDING TASK', PENDINGAPI, response.data);
        setPendingTask(response.data);
      })
      .catch(error => {
        console.log('ADMIN ALL PENDING TASK', error);
      });
  };

  let getAllEmpy = () => {
    axios
      .get(
        `${hostName}/api2/SendEmployee/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
      )
      .then(response => {
        console.log('ALL EMPLOYEE', response.data);
        setAllEmpy(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const completedTasks = allEmpytask?.filter(
    each =>
      each.status === 'completed_after_time' ||
      each.status === 'completed_on_time',
  );
  const pendingTasks = allEmpytask?.filter(each => each.status === 'pending');

  const overDueTasks = allEmpytask?.filter(each => each.status === 'overdue');
  console.log('ADMIN ALL TASKS', pendingTasks);

  useEffect(() => {
    getallTask();
    getAllEmpy();
    getPendingProjects();
    getOverdue();
  }, []);

  let getOverdue = () => {
    axios
      .get(
        `${hostName}/api/CreatedProjectsView/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/over_due/`,
      )
      .then(response => {
        setOverDueData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const allStatsFields = [
    {
      name: 'projects',
      count: allEmpytask?.length || 0,
      image: ProjectsLogo,
    },
    {
      name: 'tasks',
      count: 0,
      image: TasksLogo,
    },
    {
      name: 'employee',
      count: allEmpy?.length || 0,
      image: EmployeeLogo,
    },
    {
      name: 'stages',
      count: 0,
      image: StagesLogo,
    },
    {
      name: 'completed',
      count: completedTasks?.length || 0,
      image: CompletedLogo,
    },
    {
      name: 'overdue',
      count: overdueData?.length || 0,
      image: OverdueLogo,
    },
    {
      name: 'pending',
      count: pendingTask?.length || 0,
      image: PendingLogo,
    },
  ];

  const [active, setActive] = useState();
  const [status, setStatus] = useState();
  return (
    <div className="row">
      <main className="">
        <div className="flex flex-wrap gap-16  p-0 justify-center">
          {allStatsFields?.map((field, idx) => {
            return (
              <div
                className=""
                key={idx}
              >
                <StatsCard
                  fieldCount={field.count}
                  fieldImage={field.image}
                  fieldName={field.name}
                  active={active}
                  setActive={setActive}
                  setStatus={setStatus}
                />
              </div>
            );
          })}
          <FilledStatus
            status={status}
            setStatus={setStatus}
            active={active}
            setActive={setActive}
          />
        </div>

        <div className="grid grid-cols-10 gap-4 mt-4 mx-10  ">
          {/* Sticky Notes - Left Side */}
          <div className="col-span-7 bg-white h-max rounded-3xl shadow-md">
            <StickyNotes1 />
          </div>

          {/* Right Side - Analytics Sections */}
          <div className="col-span-3 flex flex-col gap-4 h-full">
            {/* Analytics */}
            <div className="bg-white px-4 py-3 rounded-3xl shadow-md">
              <p className="text-lg font-semibold">Analytics</p>
              <EmployeeAnalytics />
            </div>
            {/* Employee Tasks Analysis */}
            <div className="bg-white px-4 py-[21px] rounded-3xl shadow-md">
              <EmployeeTasksAnalysis />
            </div>
          </div>
        </div>

        <div
          id="details"
          className="grid grid-cols-12 grid-rows-1 gap-x-2 mt-4 rounded-lg"
        >
          <section className="col-span-6 rounded-2xl dascolbg">
            {allEmpy &&
              (active === 'employee' || active === 'filledStatus') && (
                <Members
                  data={allEmpy}
                  status={status}
                />
              )}
            {allEmpytask && active === 'projects' && (
              <ProjectList data={allEmpytask} />
            )}
            {/* {allEmpy && active === 'completed' && (
              <PendingList
                data={completedTasks}
                title={'Completed Tasks'}
                to={'/dashboard/completedTask'}
              />
            )} */}
            {allEmpy && active === 'pending' && (
              <PendingList
                data={pendingTask}
                title={'Pending Tasks'}
                to={'/dashboard/pendingTask'}
              />
            )}
            {/* {allEmpy && active === 'overdue' && (
              <PendingList
                data={overDueTasks}
                title={'Overdue Tasks'}
                to={'/dashboard/completedTask'}
              />
            )} */}
          </section>
          {
            <main className="col-span-6 rounded-2xl dascolbg">
              <div className="rounded-lg h-full w-full p-2">
                <AdminDas3 />
              </div>
            </main>
          }
        </div>

        {/* <div className="mt-3">
          <AllProjects />
        </div> */}
      </main>
    </div>
  );
};

export default ADminDas2;
