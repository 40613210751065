import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { hostName, hrapi } from '../App';
import { Store } from '../Context/StateStore';
import { Modal, Spinner } from 'react-bootstrap';
import StickyNotes from '../Component/Dashboard/StickyNotes/StickyNotes';
import HelloUser from '../Component/Dashboard/HelloUser/HelloUser';
const Login = () => {
  let [loading, setloading] = useState(false);
  let [searchParams] = useSearchParams();
  let { setUser, set } = useContext(Store);
  // State variables to store form data
  console.log(searchParams.get('login'));

  let [emailModal, setEmailModal] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showpassword, setshowpassword] = useState(false);
  const navigate = useNavigate();
  let [image, setImage] = useState(
    JSON.parse(localStorage.getItem('coverimage')),
  );
  let [optmodal, setoptmodal] = useState(false);
  let [apicalling, setapicalling] = useState(false);
  let { activepage, setactive, user, apicall } = useContext(Store);
  let [errorMessage, setErrorMessage] = useState();
  let handleCover = e => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        localStorage.setItem('coverimage', JSON.stringify(reader.result));
      };
      reader.readAsDataURL(file);
    }
  };
  let [emailError, seterrorEmail] = useState();
  useEffect(() => {
    setactive('profile');
    apicall();
    console.log(user);
  }, []);
  let [spassword, setpassword] = useState({
    enterpassword: '',
    confirmPassword: '',
  });
  let handlePassword = e => {
    let { value, name } = e.target;
    setpassword(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  let [recievedOTP, setRecievedOTP] = useState();
  let [enterOTP, setEnterOtp] = useState();
  let handleOtp = () => {
    // e.preventDefault()
    axios
      .get(
        `${hostName}/api/ChangePassword${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
      )
      .then(response => {
        alert('OTP sended successfull');
        setoptmodal(true);
        setapicalling(false);
        setRecievedOTP(response.data.OTP);
        console.log(response.data.OTP);
      })
      .catch(error => {
        console.log(error);
        setapicalling(false);
      });
  };
  let changePassword = e => {
    e.preventDefault();
    if (enterOTP == recievedOTP) {
      if (spassword.confirmPassword == spassword.enterpassword) {
        setErrorMessage('');
        axios
          .put(`${hostName}/api/ForgetPassword/${username}/`, {
            password: spassword.enterpassword,
          })
          .then(response => {
            alert('Password has been changed');
            setoptmodal(false);
          })
          .catch(error => {
            console.log(error);
            alert('Error aquired');
          });
      } else {
        setErrorMessage('*Password mismatch');
      }
    } else {
      setErrorMessage('* OTP mismatch ');
    }
  };
  // Function to handle form submission
  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('EmployeeId', username);
    formData.append('Password', password);
    setloading(true);
    if (username != '' && password != '') {
      // alert("triggered")
      axios
        .post(`${hrapi}/root/login`, formData)
        .then(r => {
          console.log('data sent succssfully');
          console.log('USER', r.data);

          sessionStorage.setItem('user', JSON.stringify(r.data));
          sessionStorage.setItem('daspk', JSON.stringify(r.data.pk));
          sessionStorage.setItem('dasid', JSON.stringify(r.data.employee_id));
          sessionStorage.setItem('email', JSON.stringify(r.data.email));
          sessionStorage.setItem('status', JSON.stringify(r.data.Dash_Status));
          // return
          setUser(r.data);
          console.log(r.data);
          navigate('/Dashboard');
          window.location.reload();
          document.getElementById('errorMessage').innerHTML = '';
          setloading(false);
        })
        .catch(e => {
          console.log('error', e);
          setloading(false);
          if (e.response && e.response.data) {
            document.getElementById('errorMessage').innerHTML = e.response.data;
          }
        });
    } else {
      setloading(false);
      document.getElementById('errorMessage').innerHTML = 'Enter the inputs';
    }
  };
  let handleEmail = e => {
    e.preventDefault();
    setapicalling(true);
    setEmailModal(false);
    axios
      .post(`${hostName}/api/ForgetPassword/${username}/`)
      .then(response => {
        setRecievedOTP(response.data.OTP);
        console.log(response.data.OTP);
        setoptmodal(true);
        alert('OTP sended');
        setapicalling(false);
      })
      .catch(error => {
        alert('Error Acquired ');
        console.log(error);
      });
  };
  return (
    <div className="h-[100vh] relative poppins flex ">
      <img
        src={require('../Assests/loginDas.jpg')}
        className="absolute -z-0 h-[100vh] w-[100vw] object-cover "
        alt=""
      />
      <div
        style={{ backgroundColor: '#FDEBEC80' }}
        className="m-auto z-10 border-none shadow-lg p-3 col-12 rounded col-sm-7  col-xl-4"
      >
        <img
          src={require('../Assests/meridawebsitelogo.png')}
          className=" mx-auto my-3"
          width={160}
          alt="Company "
        />
        <h2 className="text-center text-slate-900">Welcome Back</h2>
        <form
          onSubmit={handleSubmit}
          className="flex w-5/6 mx-auto text-white flex-col my-3 items-center"
        >
          <div className="w-full">
            <label htmlFor="username"></label>
            <input
              placeholder="Email Address"
              className=" w-full border-violet-600 rounded-lg text-slate-500 
                            px-3 py-[12px] my-3 block border-2  outline-none "
              type="text"
              id="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="w-full">
            <div className=" py-[12px] flex border-2 border-violet-600 items-center pe-3 bg-white w-full rounded-lg">
              <input
                placeholder="Enter the password"
                className=" text-slate-500 px-3 block border-2 w-full outline-none border-none border-slate-700"
                type={`${showpassword ? 'text' : 'password'}`}
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <button
                type="button"
                onClick={e => {
                  setshowpassword(!showpassword);
                  e.preventDefault();
                }}
                className="text-black mb-0"
              >
                {showpassword ? 'Hide' : 'Show'}{' '}
              </button>
            </div>
          </div>
          <div className="flex w-full mt-4 justify-between">
            {/* <p onClick={() => setEmailModal(true)} className='text-pink-500 cursor-pointer text-decoration-underline underline-offset-2 mx-auto w-fit'>Forgot password?</p> */}
            {/* <p onClick={()=>navigate('/registeration')} className='text-pink-500 cursor-pointer text-decoration-underline underline-offset-2 text-start w-fit'>For registeration</p> */}
          </div>
          <p
            className="h-[30px] text-red-600"
            id="errorMessage"
          ></p>
          <button
            type="submit"
            className="bg-violet-600 w-full rounded p-2 px-4 hoverup text-white"
          >
            {loading ? 'Loading...' : 'Login'}{' '}
          </button>
        </form>
      </div>
      {emailModal && (
        <Modal
          centered
          show={emailModal}
          onHide={() => setEmailModal(false)}
        >
          <Modal.Header closeButton>
            Enter the Email for the Verification
          </Modal.Header>
          <Modal.Body>
            Email :
            <input
              type="email"
              value={username}
              onChange={e => setUsername(e.target.value)}
              className="mx-2 p-2 px-3 outline-none bg-slate-50"
            />
            <p className="text-red-600 h-[30px]">{emailError}</p>
            <button
              onClick={handleEmail}
              className="block my-2 ms-auto p-2 px-3 bg-blue-600 text-white rounded"
            >
              {' '}
              Send OTP
            </button>
          </Modal.Body>
        </Modal>
      )}
      {optmodal && (
        <Modal
          centered
          show={optmodal}
          onHide={() => setoptmodal(false)}
        >
          <Modal.Header closeButton>Change the Password</Modal.Header>
          <Modal.Body>
            OTP :
            <input
              type="text"
              onChange={e => setEnterOtp(e.target.value)}
              className="p-2 mx-2 outline-none bg-slate-50"
            />
            <div>
              Password :
              <input
                type="text"
                onChange={handlePassword}
                name="enterpassword"
                value={spassword.enterpassword}
                className="p-2 mx-2 outline-none bg-slate-50 my-3"
              />
            </div>
            <div>
              Confirm Password :
              <input
                type="password"
                onChange={handlePassword}
                name="confirmPassword"
                value={spassword.confirmPassword}
                className="p-2 mx-2 outline-none bg-slate-50"
              />
            </div>
            <p className="text-red-600 h-[30px]  ">{errorMessage} </p>
            <div className="my-2 ms-auto w-fit">
              <button
                onClick={changePassword}
                className="rounded p-2 px-3 mx-2 bg-slate-600 text-white"
              >
                confirm
              </button>
              <button
                onClick={handleOtp}
                className="rounded p-2 px-3 bg-blue-600 text-white"
              >
                Generate OTP
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {apicalling && (
        <div
          className={`${
            apicalling ? '' : 'd-none '
          } h-[100vh] w-full absolute top-0 bg-slate-950 opacity-20 flex items-center justify-center`}
        >
          <Spinner
            className="w-fit m-auto"
            variant="info"
            animation="border"
          />
        </div>
      )}
    </div>
  );
};

export default Login;
