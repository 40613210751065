function DasDataSwitcher({ approved, setApproved }) {
  return (
    <div className="relative w-full flex">
      <div className="flex rounded-xl w-[70%] items-center justify-between mx-auto z-10">
        <button
          className={`relative p-2 transition-all duration-300 ${
            approved === 'approved'
              ? 'border-b-4 border-orange-500 text-orange-500'
              : 'border-b-4 border-transparent text-gray-600'
          }`}
          onClick={() => setApproved('approved')}
        >
          Approved
        </button>
        <button
          className={`relative p-2 transition-all duration-300 ${
            approved === 'pending'
              ? 'border-b-4 border-orange-500 text-orange-500'
              : 'border-b-4 border-transparent text-gray-600'
          }`}
          onClick={() => setApproved('pending')}
        >
          Not Approved
        </button>
        <button
          className={`relative p-2 transition-all duration-300 ${
            approved === 'rejected'
              ? 'border-b-4 border-orange-500 text-orange-500'
              : 'border-b-4 border-transparent text-gray-600'
          }`}
          onClick={() => setApproved('rejected')}
        >
          Rejected
        </button>
      </div>
      <div className="w-full h-1 bg-slate-200 absolute bottom-0 z-0 mx-auto"></div>
    </div>
  );
}

export default DasDataSwitcher;
