import { ClockIcon } from '@mui/x-date-pickers';
import axios from 'axios';
import { useState } from 'react';
import { hostName } from '../../App';

function TASEdit({ obj, handleChange, emailId, getCurrentDate, calenderDate }) {
  const [remarks, setRemarks] = useState(obj.remarks2);

  const changeRemarks = (id, remarks) => {
    axios
      .patch(
        `${hostName}/api/DASView/${JSON.parse(
          sessionStorage.getItem('email'),
        )}/`,
        { id: id, remarks2: remarks },
      )
      .then(response => {
        console.log(response.data);
        // console.log(email);
        // getDas(calenderDate, email)
        // alert('Action has been changed')
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className=" w-full h-fit  border-2 bg-white p-3 py-2 rounded-lg ">
      <div className="border-s-[3px] p-2 py-0 my-2 border-violet-600 ">
        <h5 className="mb-2 text-lg">{obj.action_planned} </h5>
        <hr className="m-0 mb-2" />
        <textarea
          onChange={e => {
            setRemarks(e.target.value);
            handleChange(e, obj.id);
            changeRemarks(obj.id, e.target.value);
          }}
          value={remarks}
          disabled={emailId || getCurrentDate() != calenderDate}
          className="m-0 w-full outline-none text-xs "
        ></textarea>
      </div>
      <p className="text-sm flex items-center gap-2 mb-0">
        {' '}
        <ClockIcon fontSize="" /> {obj.assigned_time} -{' '}
        {obj.estimated_completed_time}{' '}
      </p>
    </div>
  );
}

export default TASEdit;
