import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Context/StateStore';
import Loading from '../Component/Loading';
import { useNavigate } from 'react-router-dom';
import AdminTaskListing from '../Component/AdminTaskListing';
import axios from 'axios';
import { hostName } from '../App';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import PlusIcon from '../SVG/PlusIcon';
import DownloadButton from '../Component/DownloadButton';

const TaskList = () => {
  let { setactive, allDetails, apicall, user, getCurrentDate } =
    useContext(Store);
  console.log(allDetails);
  let status = JSON.parse(sessionStorage.getItem(`status`));
  let [loading, setloading] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  let navigate = useNavigate();
  useEffect(() => {
    setactive('task');
  }, []);
  let changeProjectStatus = (id, status) => {
    setloading(id);
    axios
      .post(
        `${hostName}/api/UpdateProjectStatus/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        {
          pid: id,
          status: status,
        },
      )
      .then(response => {
        console.log(response.data);
        apicall();
        setloading(null);
        // window.location.reload()
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data, {
            theme: 'colored',
            position: 'top-center',
            autoClose: 3000,
          });
        }
        console.log(error);
        setloading(null);
      });
  };

  let [temp, settemp] = useState();

  let handleSorting = e => {
    let value = e.target.value;
    if (value == '') {
      settemp(allDetails.all_tasks);
    }
    if (value == 'pi') {
      let temporary = [...allDetails.all_tasks].sort(
        (a, b) => b.priority - a.priority,
      );
      settemp(temporary);
    }
    if (value == 'du') {
      // alert('hellow')
      let temporary = [...allDetails.all_tasks].sort((a, b) => {
        const dateA = new Date(a.due_date);
        const dateB = new Date(b.due_date);

        // Compare the dates
        return dateA - dateB;
      });
      settemp(temporary);
    }
  };
  useEffect(() => {
    if (allDetails && allDetails.all_tasks) {
      settemp(allDetails.all_tasks);
      console.log(allDetails.all_tasks);
    }
  }, []);
  let [show, setshow] = useState();
  let [filterData, setFilterData] = useState();
  let [task, settask] = useState({
    task_name: '',
    priority: null,
    remarks: '',
    due_date: '',
    created_by: JSON.parse(sessionStorage.getItem('dasid')),
  });
  let handleChange = e => {
    let { name, value } = e.target;
    if (name == 'priority' && Number(value) > 10) {
      // alert('hellow')
      value = 10;
    }
    if (name == 'priority' && Number(value) < 0) {
      // alert('hellow')
      value = '';
    }
    if (name == 'due_date' && value < getCurrentDate()) {
      value = getCurrentDate();
    }
    settask(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  let submitTask = () => {
    if (task.due_date != '' && task.priority != '' && task.task_name != '') {
      axios
        .post(
          `${hostName}/api2/ProjectCreationByEmployee/${JSON.parse(
            sessionStorage.getItem('dasid'),
          )}/`,
          task,
        )
        .then(response => {
          toast.success('Project Has been Created', {
            position: 'top-center',
            theme: 'colored',
            autoClose: 3000,
          });
          apicall();
          settask({
            task_name: '',
            priority: null,
            remarks: '',
            due_date: '',
            created_by: JSON.parse(sessionStorage.getItem('dasid')),
          });
          setshow(false);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setErrorMessage('Fill all the required fields');
    }
  };
  return (
    <div className="poppins p-3 ">
      {allDetails !== undefined && status ? (
        <>
          {(status.toLowerCase() === 'employee' ||
            status.toLowerCase() === 'team_leader') && (
            <p>Total : {allDetails.all_tasks.length} </p>
          )}

          <div className="flex  ">
            {(status === 'employee' || status === 'team_leader') && (
              <button
                onClick={() => setshow(true)}
                className="bg-[#6B6DD8] px-2 text-white py-1 rounded-md"
              >
                + Create
              </button>
            )}
            {/* {(status === 'employee' || status === 'team_leader') && (
              <button
                onClick={() => setshow(true)}
                className={`p-3 fixed w-fit ms-auto bottom-10 
                right-10 rounded-full bg-amber-700 text-white `}
              >
                <PlusIcon />
              </button>
            )} */}
            <div className="ms-auto">
              {allDetails !== undefined &&
                allDetails.all_tasks !== undefined &&
                (status.toLowerCase() === 'employee' ||
                  status.toLowerCase() === 'team_leader') && (
                  <DownloadButton
                    data={allDetails.all_tasks}
                    name={`All_Tasks_${user && user.name}`}
                    type="all"
                  />
                )}
            </div>
          </div>
          {/* <select onChange={handleSorting} className='p-2 outline-none ms-auto flex bg-slate-50 rounded  my-3' name="" id="">
            <option value="">sort</option>
            <option value="pi">Priority H to L</option>
            <option value="du"> Due-Date C to L </option>

          </select> */}
          <div
            className={`my-2  overflow-x-scroll 
            border-slate-500  table-bordered   ${
              status.toLowerCase() === 'admin' ? 'd-none' : ''
            } `}
          >
            <table className="w-full table-auto ">
              <tr>
                <th>
                  <p className="px-2  sm:w-full"> SI.No</p>
                </th>
                <th>
                  <p className="px-2 sm:w-full"> Project Name</p>
                </th>
                <th>
                  <p className="px-2 sm:w-full">Created Date </p>
                </th>

                <th>
                  <p className="px-2 sm:w-full">Created by</p>
                </th>

                <th>
                  <p className="px-2 sm:w-full">Approved by</p>
                </th>

                <th>
                  <p className="px-2 sm:w-full">Approved Status</p>
                </th>

                <th>
                  <p className="px-2 sm:w-full">Approved Date</p>
                </th>

                <th>
                  <p className="px-2 w100px">Due date</p>
                </th>
                <th>
                  <p className="px-2 sm:w-full">Completed Date </p>
                </th>

                <th>
                  <p className="px-2 sm:w-full">Remarks</p>
                </th>
                <th>
                  <p className="px-2 min-w-32 sm:w-full">Task Status</p>
                </th>
                <th>
                  <p className="px-2 min-w-32 sm:w-full">Action </p>
                </th>
              </tr>
              {allDetails !== undefined &&
              allDetails.all_tasks !== undefined ? (
                allDetails.all_tasks.map((x, index) => {
                  console.log('ALLX', x);
                  return (
                    <tr
                      className={`  ${
                        index % 2 == 0 ? 'bg-slate-50' : 'bg-slate-100'
                      } text-sm h-[50px] `}
                    >
                      <td className="px-2">{index + 1} </td>
                      <td
                        onClick={() => {
                          navigate(`/dashboard/taskdetails/${x.task_name.id}`);
                        }}
                        className="cursor-pointer bg-zinc-200 px-2 
                          max-w-[200px] text-wrap hover:bg-slate-200"
                      >
                        {x.task_name.task_name}{' '}
                      </td>
                      <td className="px-2">
                        {x.task_name.created_date.slice(0, 10)}{' '}
                      </td>
                      <td className="px-2">
                        {x.created_by.name != null ? x.created_by.name : ''}
                      </td>

                      <td className="px-2 capitalize">
                        {x?.task_name?.approved_by?.name}
                      </td>

                      <td className="px-2 capitalize">
                        {console.log('THIS', x.task_name.approved_status)}
                        {x?.task_name?.approved_status}
                      </td>

                      <td className="px-2">
                        {x?.task_name?.approved_date?.slice(0, 10)}
                      </td>

                      <td className="px-2">
                        {x.task_name.due_date.slice(0, 10)}
                      </td>
                      <td className="px-2">
                        {' '}
                        {x.completed_date
                          ? x.completed_date.slice(0, 10)
                          : '----'}{' '}
                      </td>
                      <td className="px-2 max-w-[200px] text-wrap ">
                        {x.task_name.remarks}
                      </td>
                      <td className="px-2">{x.task_name.status} </td>
                      <td className="px-2">
                        {loading != x.task_name.id && (
                          <select
                            name=""
                            id=""
                            onChange={e => {
                              changeProjectStatus(
                                x.task_name.id,
                                e.target.value,
                              );
                            }}
                            className="outline-none bg-transparent"
                          >
                            <option
                              value={
                                x.task_name.status.slice(0, 3) == 'com'
                                  ? 'True'
                                  : 'False'
                              }
                            >
                              {x.task_name.status.slice(0, 3) == 'com'
                                ? 'completed'
                                : 'pending'}{' '}
                            </option>
                            <option
                              value={
                                x.task_name.status.slice(0, 3) == 'com'
                                  ? 'False'
                                  : 'True'
                              }
                            >
                              {x.task_name.status.slice(0, 3) == 'com'
                                ? 'pending'
                                : 'completed'}{' '}
                            </option>
                          </select>
                        )}
                        {loading == x.task_name.id && <p> Loading....</p>}
                      </td>

                      {/* Add more data cells as needed */}
                    </tr>
                  );
                })
              ) : (
                <Loading />
              )}

              {/* Add more rows as needed */}
            </table>

            {show && (
              <Modal
                centered
                className=""
                show={show}
                onHide={() => setshow(false)}
              >
                <Modal.Header
                  className="p-3 "
                  closeButton
                >
                  Adding the Task
                </Modal.Header>
                <Modal.Body className="p-3">
                  <form
                    action=""
                    className="flex justify-between flex-wrap"
                  >
                    <div className="sm:w-[45%] ">
                      Task Name : <span className="text-red-600">* </span>
                      <input
                        type="text"
                        onChange={handleChange}
                        name="task_name"
                        className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                        placeholder="Enter the task name "
                      />
                    </div>
                    <div className="sm:w-[45%] ">
                      Priority : <span className="text-red-600">* </span>
                      <input
                        type="number"
                        value={task.priority}
                        onChange={handleChange}
                        name="priority"
                        className=" border-1 p-2 block my-2 rounded w-32 nospin bg-slate-50 outline-none"
                        placeholder="Enter in 1- 10 "
                      />
                    </div>
                    <div className="sm:w-[45%] ">
                      Remarks :
                      <textarea
                        type="text"
                        onChange={handleChange}
                        name="remarks"
                        className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                        placeholder="Enter the remarks "
                      />
                    </div>
                    <div className="sm:w-[45%] ">
                      Due Date : <span className="text-red-600">* </span>
                      <input
                        type="datetime-local"
                        value={task.due_date}
                        onChange={handleChange}
                        name="due_date"
                        className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                        placeholder="Enter the task name "
                      />
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <p className="text-red-600 ">{errorMessage} </p>
                  <button
                    onClick={submitTask}
                    className="p-2 px-3 bg-green-600 text-white rounded"
                  >
                    Create
                  </button>
                  <button
                    onClick={() => setshow(false)}
                    className="p-2 px-3 bg-red-600 text-white rounded"
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
          {status.toLowerCase() !== 'employee' &&
            status.toLowerCase() !== 'team_leader' && (
              <div className={` `}>
                <AdminTaskListing />
              </div>
            )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default TaskList;
