import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Context/StateStore';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { hostName } from '../App';
import Profile from '../Assests/profilepic.webp';

const Profilepage = () => {
  let [image, setImage] = useState(
    JSON.parse(localStorage.getItem('coverimage')),
  );
  let [optmodal, setoptmodal] = useState(false);
  let [apicalling, setapicalling] = useState(false);
  let { activepage, setactive, user, apicall } = useContext(Store);
  let [errorMessage, setErrorMessage] = useState();
  let handleCover = e => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        localStorage.setItem('coverimage', JSON.stringify(reader.result));
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    setactive('profile');
    apicall();
    console.log(user, 'hellow');
  }, []);
  let [password, setpassword] = useState({
    enterpassword: '',
    confirmPassword: '',
  });
  let handlePassword = e => {
    let { value, name } = e.target;
    setpassword(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  let [recievedOTP, setRecievedOTP] = useState();
  let [enterOTP, setEnterOtp] = useState();
  let handleOtp = e => {
    e.preventDefault();
    setapicalling(true);
    axios
      .get(
        `${hostName}/api/ChangePassword/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
      )
      .then(response => {
        alert('OTP sended successfull');
        setoptmodal(true);
        setapicalling(false);
        setRecievedOTP(response.data.OTP);
        console.log(response.data.OTP);
      })
      .catch(error => {
        console.log(error);
        setapicalling(false);
      });
  };
  let changePassword = e => {
    e.preventDefault();
    if (enterOTP == recievedOTP) {
      if (password.confirmPassword == password.enterpassword) {
        setErrorMessage('');
        axios
          .post(
            `${hostName}/api/ChangePassword/${JSON.parse(
              sessionStorage.getItem('dasid'),
            )}/`,
            {
              password: password.enterpassword,
            },
          )
          .then(response => {
            alert('Password has been changed');
            setoptmodal(false);
          })
          .catch(error => {
            console.log(error);
            alert('Error aquired');
          });
      } else {
        setErrorMessage('*Password mismatch');
      }
    } else {
      setErrorMessage('* OTP mismatch ');
    }
  };
  let handleProfileImage = e => {
    var fileSize = e.target.files[0].size; // Size of the selected file in bytes
    var maxSize = 1024 * 1024; // Maximum allowed size in bytes (1MB)

    console.log('File size:', fileSize);

    if (fileSize > maxSize) {
      alert('Image Size is big');
      console.log('Image size is big');
      return;
    } else {
      const formData = new FormData();
      formData.append('profile_pic', e.target.files[0]);
      axios
        .patch(
          `${hostName}/api/UpdateProfile/${JSON.parse(
            sessionStorage.getItem('dasid'),
          )}/`,
          formData,
        )
        .then(response => {
          apicall();
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <div className=" relative container my-3">
        <main className="container min-h-[50vh] bg-white rounded-xl p-5 flex items-center justify-center">
          <section className="min-h-[50vh] col-lg-10 ">
            <img
              src={user?.EmployeeProfile || Profile}
              className="w-40 h-40 object-cover flex rounded-full mx-auto border-2 "
              alt="Profile "
            />
            <label
              htmlFor="uploadimage"
              className="mx-auto flex"
            >
              <p className="text-center mx-auto w-fit my-2 text-violet-600">
                {' '}
                Upload Photo
              </p>
            </label>
            <input
              onChange={e => handleProfileImage(e)}
              type="file"
              id="uploadimage"
              className="hidden"
            />

            {user && (
              <article className="flex flex-wrap">
                <p className="text-lg w-full sm:w-1/2 mb-0 ">
                  Name :
                  <span className="fw-normal block border-2 rounded my-2 capitalize  p-2 bg-violet-50  w-10/12 ">
                    {user.full_name}{' '}
                  </span>
                </p>
                <p className="text-lg w-full sm:w-1/2 mb-0 ">
                  Department :
                  <span className="fw-normal block border-2 rounded my-2 capitalize  p-2 bg-violet-50  w-10/12 ">
                    {user.Department}
                  </span>
                </p>
                <p className="text-lg w-full sm:w-1/2 mb-0 ">
                  Email :
                  <span className="fw-normal block border-2 rounded my-2  p-2 bg-violet-50  w-10/12 ">
                    {user.email}{' '}
                  </span>
                </p>
                <p className="text-lg w-full sm:w-1/2 mb-0 ">
                  Role :
                  <span className="fw-normal block border-2 rounded my-2 capitalize p-2 bg-violet-50  w-10/12 ">
                    {user.Position}{' '}
                  </span>
                </p>
                <p className="text-lg w-full sm:w-1/2 mb-0 ">
                  Position :
                  <span className="fw-normal block border-2 rounded my-2  capitalize p-2 bg-violet-50  w-10/12 ">
                    {user.Dash_Status}{' '}
                  </span>
                </p>
                <p className="text-lg w-full sm:w-1/2 mb-0 ">
                  Reporting :
                  <span className="fw-normal block border-2 rounded my-2 capitalize  p-2 bg-violet-50  w-10/12 ">
                    {user.Reporting_To}{' '}
                  </span>
                </p>
              </article>
            )}
            {
              <button
                disabled={apicalling}
                onClick={handleOtp}
                className="rounded-full px-3 p-2 bg-violet-600 text-white mx-auto flex my-3"
              >
                Change password
              </button>
            }
          </section>
          {/* Form */}
        </main>
        {optmodal && (
          <Modal
            centered
            show={optmodal}
            onHide={() => setoptmodal(false)}
          >
            <Modal.Header closeButton>Change the Password</Modal.Header>
            <Modal.Body>
              OTP :
              <input
                type="text"
                onChange={e => setEnterOtp(e.target.value)}
                className="p-2 mx-2 outline-none bg-slate-50"
              />
              <div>
                Password :
                <input
                  type="text"
                  onChange={handlePassword}
                  name="enterpassword"
                  value={password.enterpassword}
                  className="p-2 mx-2 outline-none bg-slate-50 my-3"
                />
              </div>
              <div>
                Confirm Password :
                <input
                  type="password"
                  onChange={handlePassword}
                  name="confirmPassword"
                  value={password.confirmPassword}
                  className="p-2 mx-2 outline-none bg-slate-50"
                />
              </div>
              <p className="text-red-600 h-[30px]  ">{errorMessage} </p>
              <div className="my-2 ms-auto w-fit">
                <button
                  onClick={changePassword}
                  className="rounded p-2 px-3 mx-2 bg-slate-600 text-white"
                >
                  confirm
                </button>
                <button
                  onClick={handleOtp}
                  className="rounded p-2 px-3 bg-blue-600 text-white"
                >
                  Generate OTP
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {apicalling && (
          <div
            className={`${
              apicalling ? '' : 'd-none '
            } h-[90vh] w-full absolute top-0 flex items-center justify-center`}
          >
            <Spinner
              className="w-fit m-auto"
              variant="info"
              animation="border"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Profilepage;
