import { ClockIcon } from '@mui/x-date-pickers';
import ArrowRghtIconSVG from '../../SVG/ArrowRghtIconSVG';

function TasDetails({
  obj,
  change_status,
  getCurrentDate,
  calenderDate,
  emailId,
}) {
  return (
    <div className=" w-full my-3 h-fit relative border-2 bg-white p-3 py-2 rounded-lg ">
      <div className="border-s-[3px] p-2 py-0 my-2 border-violet-600 ">
        <div>
          <h5 className="mb-2 text-lg">{obj.action_planned} </h5>
        </div>
        <hr className="m-0 mb-2" />
        <p className="m-0 text-xs text-[#D2D2D2]">{obj.remarks} </p>
      </div>
      {/* <div
        className={` bg-slate-950 px-5 ${
          obj.action_acheived === 'completed' ? 'opacity-0 ' : 'bgopacity'
        } bg-opacity-10 flex left-0 rounded-lg absolute top-0 h-full w-full`}
      >
        <button
          onClick={e => change_status(obj.id, 'completed')}
          disabled={getCurrentDate() !== calenderDate || emailId}
          className="ms-auto my-auto flex"
        >
          {' '}
          <ArrowRghtIconSVG size={30} />{' '}
        </button>
      </div> */}
      <div className="flex justify-between">
        <p className="text-sm flex items-center gap-2 mb-0">
          {' '}
          <ClockIcon className="text-[#D1D1D1] " /> {obj.assigned_time} -{' '}
          {obj.estimated_completed_time}{' '}
        </p>
        <div className="flex gap-x-2 ">
          {/* <button className="bg-red-500 px-2 rounded-md text-white text-sm py-1">
            Not Yet
          </button> */}
          {obj.action_acheived !== 'completed' && (
            <button
              className="bg-green-500 px-2 rounded-md text-white text-sm py-1"
              onClick={e => change_status(obj.id, 'completed')}
            >
              Completed
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TasDetails;
