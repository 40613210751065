import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function NavField({
  setactive,
  activepage,
  children,
  fieldName,
  fieldId,
  toPage,
  fieldToolTip,
}) {
  const navigate = useNavigate();

  const handleNavigate = (path, page) => {
    navigate(path);
    setactive(page);
  };

  const renderTooltip = text => {
    return <Tooltip id="button-tooltip">{text}</Tooltip>;
  };

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 150, hide: 200 }}
      overlay={renderTooltip(fieldToolTip)}
    >
      <div
        onClick={() => handleNavigate(toPage, fieldId)}
        className={`flex w-[185px ] items-end transi mb-[14px] w-[185px] mt-3 gap-3  cursor-pointer py-[9px] pl-[24px] font-sem rounded ${
          activepage === fieldId ? 'bg-white text-[#6B6DD8]   ' : 'text-white'
        } `}
      >
        <div>{children}</div>
        <p
          style={{
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: '0.5px',
          }}
          className={`transi m-0  `}
        >
          {fieldName}
        </p>
      </div>
    </OverlayTrigger>
  );
}

export default NavField;
