import React, { useContext } from 'react';
import NavBarComponent from '../Component/NavBarComponent';
import SideBar from '../Component/SideBar';
import { Route, Routes } from 'react-router-dom';
import { Store } from '../Context/StateStore';
import DashBoardContent from './DashBoardContent';
import TaskList from './TaskList';
import PendingList from './PendingList';
import CompletedTask from './CompletedTask';
import ComOnTime from './ComOnTime';
import ComAfterTime from './ComAfterTime';
import OverDue from './OverDue';
import { Spinner } from 'react-bootstrap';
import TaskDetails from './TaskDetails';
import PendingTaskMain from './PendingTaskMain';
import Profilepage from './Profilepage';
import EmployeeDetails from './EmployeeDetails';
import Das from './Das';
import ChattingPage from './Chatting/ChattingPage';
import DepartmentList from './ExployeeViewing/DepartmentList';
import EmployeeView from './ExployeeViewing/EmployeeView';
import ParticularEmployee from './ExployeeViewing/ParticularEmployee';
import WishesCom from '../Component/WishesCom';
import Tracker from './Tracker/Tracker';

const Dashboard = () => {
  const { currentDate, user } = useContext(Store);
  return (
    <div className="poppins bg-[#DDE1FC]">
      {true ? (
        <>
          <div className="lg:flex">
            <div className="d-none d-xl-flex ">
              <SideBar />
            </div>
            <div className="lg:flex-1">
              <WishesCom />
              <NavBarComponent />
              {/* Welcome User */}
              <Routes>
                <Route
                  path="/"
                  element={<DashBoardContent />}
                />
                <Route
                  path="/tasklist"
                  element={<TaskList />}
                />
                <Route
                  path="/chat/:id?"
                  element={<ChattingPage />}
                />
                <Route
                  path="/department/*"
                  element={<DepartmentList />}
                />
                <Route
                  path="/department/:id"
                  element={<EmployeeView />}
                />
                <Route
                  path="/reporting"
                  element={<EmployeeView />}
                />
                <Route
                  path="/tracker"
                  element={<Tracker />}
                />

                <Route
                  path="/employee/:id"
                  element={<ParticularEmployee />}
                />
                <Route
                  path="/pendingTask/:id?"
                  element={<PendingList />}
                />
                <Route
                  path="/completedTask/:id?"
                  element={<CompletedTask />}
                />
                <Route
                  path="/completedOntime/:id?"
                  element={<ComOnTime />}
                />
                <Route
                  path="/completedAfterTime/:id?"
                  element={<ComAfterTime />}
                />
                <Route
                  path="/pendingTaskList/:id?"
                  element={<PendingTaskMain />}
                />
                <Route
                  path="/overdue/:id?"
                  element={<OverDue />}
                />
                <Route
                  path="/taskdetails/:id"
                  element={<TaskDetails />}
                />
                <Route
                  path="/DasReport"
                  element={<Das />}
                />
                <Route
                  path="/profile/:id?"
                  element={<Profilepage />}
                />
                <Route
                  path="/userDetails"
                  element={<EmployeeDetails />}
                />
              </Routes>
            </div>
          </div>
        </>
      ) : (
        <div className="h-[100vh] flex ">
          <Spinner
            className="m-auto"
            animation="border"
          />
          ;
        </div>
      )}
    </div>
  );
};

export default Dashboard;
