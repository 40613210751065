function ChatSVG() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 15C19 15.5304 18.7893 16.0391 18.4142 16.4142C18.0391 16.7893 17.5304 17 17 17H5L1 21V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V15Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.7099 12V9.48H7.0859V12H4.7099ZM8.81263 12V9.48H11.1886V12H8.81263ZM12.9154 12V9.48H15.2914V12H12.9154Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ChatSVG;
