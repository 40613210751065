import { useState } from 'react';
import ProjectsDataSwitcher from './ProjectsDataSwitcher';
import { HamBurger, ProjectsLogo } from '../../../Assets/AllImages';
import { CgMenuMotion, CgProfile } from 'react-icons/cg';
import { TfiAlarmClock } from 'react-icons/tfi';
import { LuAlarmClockOff } from 'react-icons/lu';
import { FaClockRotateLeft, FaRegClock } from 'react-icons/fa6';

function AllProjects() {
  const [approved, setApproved] = useState('approved');

  return (
    <div className="bg-white p-2 rounded-lg">
      <div className="relative">
        <div className="w-[60%] mx-auto ">
          <ProjectsDataSwitcher
            approved={approved}
            setApproved={setApproved}
          />
        </div>
        <div className="w-full h-1 bg-slate-200 absolute bottom-0 z-0 mx-auto"></div>
      </div>
      <div className="flex flex-col border-b-2 p-4">
        <div className="flex gap-x-3 items-center mb-3 ">
          <div className="bg-white p-8 w-max rounded-full">
            <img
              className="object-cover mx-auto"
              src={ProjectsLogo}
              alt=""
            />
          </div>
          <div>
            <p className="m-0">Title</p>
            <div>percentage bar </div>
            <div className="flex mt-2 gap-x-2">
              {[1, 2, 3, 4, 5].map(each => {
                return <CgProfile />;
              })}
            </div>
          </div>
        </div>
        <div className="flex gap-x-6">
          <div className="flex items-center gap-x-3 ">
            <div>
              <img
                className="object-cover mx-auto text-slate-500"
                src={HamBurger}
                alt=""
              />
            </div>
            <p className="my-auto">Members</p>
          </div>
          <div className="flex items-center gap-x-3 ">
            <FaRegClock className="text-slate-500" />
            <p className="my-auto">Deadline</p>
          </div>
          <div className="flex items-center gap-x-3 ">
            <FaClockRotateLeft className="text-slate-500" />
            <p className="my-auto">Tasks</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllProjects;
