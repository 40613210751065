import { Modal } from 'react-bootstrap';
import { hostName } from '../../App';
import axios from 'axios';

function ProjectCreateModal({
  show,
  setshow,
  settask,
  task,
  handleChange,
  submitTask,
}) {
  let getData = () => {
    axios
      .get(`${hostName}/api/UpdateProjectDetails/${show}`, {})
      .then(response => {
        console.log(response.data);
        // setData(response.data);
        // setLoading(false);
      })
      .catch(error => {
        console.log(error);
        // setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        centered
        className=""
        show={show}
        onHide={() => {
          setshow(false);
          settask({
            task_name: '',
            priority: '',
            remarks: '',
            due_date: '',
            created_by: JSON.parse(sessionStorage.getItem('dasid')),
            approved_status: '',
          });
        }}
      >
        <Modal.Header
          className="p-3 "
          closeButton
        >
          Adding the Task{' '}
          {sessionStorage.getItem('selectedEmp') &&
            sessionStorage.getItem('selectedEmp') != 'all' &&
            sessionStorage.getItem('selectedEmp')}
        </Modal.Header>
        <Modal.Body className="p-3">
          <form
            action=""
            className="flex justify-between flex-wrap"
          >
            <div className="sm:w-[45%] ">
              Task Name : <span className="text-red-600"> * </span>
              <input
                type="text"
                value={task?.task_name}
                onChange={handleChange}
                name="task_name"
                className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                placeholder="Enter the task name "
              />
            </div>
            <div className="sm:w-[45%] ">
              Priority :
              <input
                type="number"
                value={task?.priority}
                onChange={handleChange}
                name="priority"
                className=" border-1 p-2 block my-2 rounded w-32 nospin bg-slate-50 outline-none"
                placeholder="Enter in 1- 10 "
              />
            </div>
            <div className="sm:w-[45%] ">
              Remarks :
              <textarea
                type="text"
                value={task?.remarks}
                onChange={handleChange}
                name="remarks"
                className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                placeholder="Enter the remarks "
              />
            </div>
            <div className="sm:w-[45%] ">
              Due Date :
              <input
                type="datetime-local"
                value={task?.due_date}
                onChange={handleChange}
                name="due_date"
                className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                placeholder="Enter the task name "
              />
            </div>
            <div className="sm:w-[45%] ">
              Approved :
              <input
                type="checkbox"
                checked={task?.approved_status}
                onChange={handleChange}
                name="approved_status"
                className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
              />
              {/* /MUIZ */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={submitTask}
            className="p-2 px-3 bg-green-600 text-white rounded"
          >
            Create
          </button>
          <button
            onClick={() => {
              setshow(false);
              settask({
                task_name: '',
                priority: '',
                remarks: '',
                due_date: '',
                created_by: JSON.parse(sessionStorage.getItem('dasid')),
              });
            }}
            className="p-2 px-3 bg-red-600 text-white rounded"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProjectCreateModal;
