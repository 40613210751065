function StatsCard({
  fieldName,
  fieldCount,
  fieldImage,
  active,
  idx,
  setActive,
  setStatus,
}) {
  const detailsElement = document.getElementById('details');

  return (
    <div
      className={`${
        active === fieldName ? 'bg-[#6B6DD8]' : 'bg-[#EBEDFD] '
      } px-3 gap-x-5 flex w-[205px] py-2 rounded-xl font-sans  cursor-pointer`}
      onClick={() => {
        fieldName === 'employee' && setStatus();

        (fieldName === 'employee' ||
          fieldName === 'pending' ||
          fieldName === 'overdue' ||
          fieldName === 'completed' ||
          fieldName === 'projects') &&
          detailsElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });

        setActive(fieldName);
      }}
    >
      <div className="flex items-center justify-center bg-white  rounded-full w-[56px] h-[56px] my-auto flex-shrink-0 ">
        <img
          className="object-fill p-2  "
          src={fieldImage}
          alt=""
        />
      </div>
      <div className="flex py-1 flex-col justify-center">
        <p className="mt-2  mb-0 capitalize text-[#A3AED0] text-xl ">
          {fieldName}
        </p>
        <h2
          className={`${active === fieldName ? 'text-white' : 'text-black'} `}
        >
          {fieldCount}
        </h2>
      </div>
    </div>
  );
}

export default StatsCard;
