import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../SVG/SearchIcon';
import { DefaultImage } from '../../Assets/AllImages';
import axios from 'axios';
import { hostName } from '../../App';

const Members = ({ data, status }) => {
  const navigate = useNavigate();

  const [filteredArray, setFilteredArray] = useState(data);
  const [filterword, setFilterword] = useState('');
  const [dasData, setDasData] = useState();

  useEffect(() => {
    setFilteredArray(data);
  }, [data]);

  useEffect(() => {
    const today = new Date();
    const todayDate = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    axios
      .get(
        `${hostName}/api/FilledDASRecords/${todayYear}/${todayMonth}/${todayDate}`,
      )
      .then(res => {
        setDasData(res.data.employees);
      })
      .catch(err => console.log(err));
  }, []);

  const filterArray = () => {
    const filteredData = data.filter(obj =>
      obj.name.toLowerCase().includes(filterword.toLowerCase()),
    );
    setFilteredArray(filteredData);
  };

  const dasFilledEmployees = useMemo(() => {
    return filteredArray?.map(employee => {
      const statusObj = dasData?.find(status => status.name === employee.name);
      return {
        ...employee,
        dasStatus: statusObj?.status,
      };
    });
  }, [filteredArray, dasData]);

  const sortedAndFilteredEmployees = useMemo(() => {
    return [...dasFilledEmployees]
      .sort((a, b) => {
        if (a.dasStatus === 'filled' && b.dasStatus !== 'filled') return -1;
        if (a.dasStatus !== 'filled' && b.dasStatus === 'filled') return 1;
        return 0;
      })
      .filter(item => !status || item?.dasStatus === status);
  }, [dasFilledEmployees, status]);

  return (
    <div className="p-2">
      <div className="flex justify-between p-2">
        <h5>Members</h5>
        <article className="p-2 flex items-center border-2 rounded-lg">
          <input
            value={filterword}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                filterArray();
              }
            }}
            onChange={e => setFilterword(e.target.value)}
            placeholder="Search & Press Enter"
            type="text"
            className="bg-transparent outline-none"
          />
          <SearchIcon />
        </article>
      </div>
      <div className="h-[70vh] scrollbar2 overflow-y-scroll px-2">
        {sortedAndFilteredEmployees?.map(x => (
          <div
            key={x.employee_id}
            className={`my-3 border-2 rounded-lg p-3 flex ${
              x.dasStatus === 'filled' ? 'border-green-500' : 'border-red-600'
            } items-center justify-between`}
          >
            <div className="flex gap-2 items-center">
              <img
                src={x.profile_pic || DefaultImage}
                alt="Profile pic"
                className="w-12 h-12 rounded-full object-cover"
              />
              <div>
                <p className="text-xl font-semibold mb-1 text-start text-slate-600">
                  {x.name}
                </p>
                <p className="mb-1 text-slate-500 font-medium text-start">
                  {x.department.department_name} ({x.position.position})
                </p>
              </div>
            </div>
            <button
              onClick={() => navigate(`/dashboard/employee/${x.employee_id}`)}
              className="p-1 bg-slate-200 text-slate-600 uppercase rounded-full px-3"
            >
              view
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Members;
