import React from 'react';
import { IoMdDownload } from 'react-icons/io';
import * as XLSX from 'xlsx';

const DownloadButton = ({ data, type, name }) => {
  const exportToExcel = () => {
    console.log(data);
    const exportData = [...data].map((obj, index) => {
      console.log(obj.due_date);
      let dataobj = {
        'SI No': index + 1,
        'Task Name': type == 'all' ? obj.task_name.task_name : obj.task_name,
        Remarks: type == 'all' ? obj.task_name.remarks : obj.remarks,
        'Created Date':
          type == 'all'
            ? obj.task_name.created_date
            : obj.created_date && obj.created_date != null
            ? obj.created_date.slice(0, 10)
            : '--',
        'Due Date':
          type == 'all'
            ? obj.task_name.due_date.slice(0, 10)
            : obj.due_date && obj.due_date != null
            ? obj.due_date.slice(0, 10)
            : '--',
        Status: type == 'all' ? obj.task_name.status : obj.status,
        'Completed Date':
          type == 'all'
            ? obj.task_name.completed_date
            : obj.completed_date && obj.completed_date != null
            ? obj.completed_date.slice(0, 10)
            : '--',
      };
      if (obj.assigned_to) {
        dataobj.assigned_to =
          obj.assigned_to && obj.assigned_to != null
            ? obj.assigned_to.name
            : 'Not assigned';
      }
      return dataobj;
    });
    console.log(exportData);
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    let worksheet;
    if (type == undefined || type != 'notes') {
      worksheet = XLSX.utils.json_to_sheet(exportData);
    }
    if (type == 'notes') {
      worksheet = XLSX.utils.json_to_sheet(data);
    }
    const header = ['ID', 'Name', 'Assigned to', 'Completed Date'];
    const headerStyle = {
      font: { bold: true, color: { rgb: 'FF0000' } }, // Red color
      fill: { fgColor: { rgb: 'FFFF00' } }, // Yellow fill
    };
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    // Generate a buffer and download the file
    XLSX.writeFile(workbook, `${name}.xlsx`);
  };
  return (
    <div className="">
      <button
        onClick={exportToExcel}
        className="p-2 px-3 ms-auto flex hover:bg-blue-700 bg-blue-600 rounded-lg text-white "
      >
        <IoMdDownload className="text-xl" />
        {/* Download{' '} */}
      </button>
    </div>
  );
};
export default DownloadButton;
