import React, { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import DailyMemo from './DailyMemo';
import dayjs from 'dayjs';

function CalendarDAS() {
  const today = new Date();
  const todayDate = today.toLocaleDateString('en-US', {
    day: '2-digit',
    weekday: 'short',
    year: '2-digit',
  });

  const [selectedMonth, setSelectedMonth] = useState(today.getMonth());
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());

  const generateMonthDays = (month, year) => {
    const days = [];
    const date = new Date(year, month, 1);
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getWeekIndexForToday = (month, year) => {
    const days = generateMonthDays(month, year);
    const todayIndex = days.findIndex(
      day =>
        day.getDate() === today.getDate() &&
        day.getMonth() === today.getMonth() &&
        day.getFullYear() === today.getFullYear(),
    );
    return Math.floor(todayIndex / 7);
  };

  const [currentWeekIndex, setCurrentWeekIndex] = useState(
    getWeekIndexForToday(selectedMonth, selectedYear),
  );

  const days = generateMonthDays(selectedMonth, selectedYear);
  const startIndex = currentWeekIndex * 7;
  const weekDays = days.slice(startIndex, startIndex + 7);

  const handleNext = () => {
    if (startIndex + 7 < days.length) {
      setCurrentWeekIndex(currentWeekIndex + 1);
    } else {
      const nextMonth = (selectedMonth + 1) % 12;
      const nextYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
      setSelectedMonth(nextMonth);
      setSelectedYear(nextYear);
      setCurrentWeekIndex(0);
    }
  };

  const handlePrevious = () => {
    if (currentWeekIndex > 0) {
      setCurrentWeekIndex(currentWeekIndex - 1);
    } else {
      const prevMonth = (selectedMonth - 1 + 12) % 12;
      const prevYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
      setSelectedMonth(prevMonth);
      setSelectedYear(prevYear);
      setCurrentWeekIndex(
        Math.floor(generateMonthDays(prevMonth, prevYear).length / 7),
      );
    }
  };

  const dateFormatter = day =>
    day.toLocaleDateString('en-US', {
      day: '2-digit',
      weekday: 'short',
      year: '2-digit',
    });

  const [selectedDate, setSelectedDate] = useState(today);

  return (
    <div className="w-full h-full rounded-3xl flex flex-col bg-white">
      {/* Header */}
      <div className="h-[76px] w-full bg-[#6B6DD8] rounded-t-2xl">
        <div className="flex items-center p-2 mt-2 mx-3 h-full justify-between">
          <p
            className="uppercase font-bold text-white"
            style={{ fontSize: '18px', lineHeight: '18px' }}
          >
            My Calendar
          </p>
          <div>
            <select
              className="mr-2 p-2 rounded bg-[#dde1fc]"
              value={selectedMonth}
              onChange={e => setSelectedMonth(Number(e.target.value))}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option
                  key={i}
                  value={i}
                >
                  {new Date(0, i).toLocaleString('default', { month: 'long' })}
                </option>
              ))}
            </select>
            <input
              type="number"
              value={selectedYear}
              onChange={e => setSelectedYear(Number(e.target.value))}
              className="p-2 w-20 rounded outline-none bg-[#dde1fc]"
            />
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between p-1 bg-[#262C46]">
        <button
          onClick={handlePrevious}
          className="bg-[#6B6DD8] p-2 rounded text-white"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={handleNext}
          className="bg-[#6B6DD8] p-2 rounded text-white"
        >
          <IoIosArrowForward />
        </button>
      </div>

      {/* Animated Days Grid */}
      <div className="bg-[#262C46] w-full h-max rounded-b-2xl  ">
        <div className="grid grid-cols-7 gap-4 px-4 py-2">
          {weekDays.map((day, index) => {
            // console.log(
            //   dateFormatter(selectedDate),
            //   todayDate,
            //   dateFormatter(day),
            //   'MUIZ TODAY',
            // );
            const isToday = dateFormatter(day) === dateFormatter(selectedDate);

            return (
              <div
                onClick={() => setSelectedDate(day)}
                key={index}
                className={`text-white flex cursor-pointer flex-col items-center ${
                  isToday ? 'bg-[#6B6DD8]' : 'bg-transparent'
                } px-7 pt-2 rounded-2xl`}
              >
                <p className="font-bold ">
                  {day.toLocaleDateString('en-US', { weekday: 'short' })}
                </p>
                <p className="">{day.getDate()}</p>
              </div>
            );
          })}
        </div>
      </div>

      <DailyMemo date={selectedDate} />
    </div>
  );
}

export default CalendarDAS;
