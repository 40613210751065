import React, { useContext, useState } from 'react';
import { Store } from '../Context/StateStore';
import { useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import HomeIcon from '../SVG/HomeIcon';
import ArrowRghtIconSVG from '../SVG/ArrowRghtIconSVG';
import { CalendarIcon } from '@mui/x-date-pickers';
import ProfileIcon from '../SVG/ProfileIcon';
import SettingIcon from '../SVG/SettingIcon';
import TaskBarIcon from '../SVG/TaskBarIcon';
import ChatIcon from '../SVG/ChatIcon';
import SignOut from '../SVG/SignOut';
import { hrpage } from '../App';
import HrmsLogo from '../SVG/HrmsLogo';
import {
  DashCompanyLogo,
  PendingIMG,
  TaskListIMG,
  ToDoListIMG,
} from '../Assets/AllImages';
import NavField from './SideBar/NavField';
import HomeSVG from '../Assets/SVG/HomeSVG';
import CalenderSVG from '../Assets/SVG/CalenderSVG';
import ChatSVG from '../Assets/SVG/ChatSVG';
import ClockSVG from '../Assets/SVG/ClockSVG';
import HRMSSVG from '../Assets/SVG/HRMSSVG';
import LogOutSVG from '../Assets/SVG/LogOutSVG';
import TaskListSVG from '../Assets/SVG/TaskListSVG';

const SideBar = () => {
  const { user, show, setshow, activepage, setactive } = useContext(Store);
  const status = JSON.parse(sessionStorage.getItem('status'));
  const [showPending, setShowPending] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  // const [width, setWidth] = useState('w-[70px]');
  const navigate = useNavigate();

  const handleNavigate = (path, page) => {
    navigate(path);
    setactive(page);
  };

  const renderTooltip = text => <Tooltip id="button-tooltip">{text}</Tooltip>;

  const iconSize = 28;

  return (
    <div className="h-[100vh] sticky top-0 bg-[#6264D5ED] ">
      <div
        // style={{ backgroundColor: 'rgb(252,246,248)' }}
        className={`sm:h-[100vh] h-full w-[250px]  overflow-y-scroll scrollbar sticky  py-3 flex flex-col items-center  poppins shadow-sm-2xl sm:shadow-violet-500 rounded transi p-2`}
      >
        {/* Top Section */}
        <section className=" flex items-center flex-col">
          {/* <div className="flex mt-[21px] mb-[58px]  "> */}
          <div className="flex mt-1 mb-3 ">
            <img
              width={145}
              className="mx-auto"
              alt="Icon"
              src={DashCompanyLogo}
              onClick={() => navigate('/dashboard')}
            />
          </div>

          <NavField
            activepage={activepage}
            setactive={setactive}
            fieldName={'Dashboard'}
            fieldToolTip={'Dashboard'}
            fieldId={'dashboard'}
            toPage={'/dashboard'}
          >
            <HomeSVG active={activepage} />
          </NavField>

          {/*  */}

          {status !== 'admin' && status !== 'employee' && (
            /* <OverlayTrigger
              placement="right"
              delay={{ show: 150, hide: 200 }}
              overlay={renderTooltip('Employee Details')}
            >
              <div
                onClick={() =>
                  handleNavigate('/dashboard/reporting', 'employee')
                }
                className={`flex transi my-2 gap-3 cursor-pointer p-[15px] rounded ${
                  activepage === 'employee'
                    ? 'bg-violet-700 text-slate-50'
                    : 'text-violet-700'
                } items-center`}
              >
                <ProfileIcon size={iconSize} />
                <p
                  className={`transi text-sm m-0 ${
                    width === 'w-[70px]' ? 'hidden' : 'w-[160px]'
                  }`}
                >
                  Employee Details
                </p>
              </div>
            </OverlayTrigger>
            */

            <NavField
              activepage={activepage}
              setactive={setactive}
              fieldName={'Employee Details'}
              fieldToolTip={'Employee Details'}
              fieldId={'employee'}
              toPage={'/dashboard/reporting'}
            >
              <ProfileIcon size={iconSize} />
            </NavField>
          )}

          <NavField
            activepage={activepage}
            setactive={setactive}
            fieldName={'DAS'}
            fieldToolTip={'Daily Activity Sheet'}
            fieldId={'das'}
            toPage={'/dashboard/dasreport'}
          >
            <CalenderSVG />
          </NavField>

          <NavField
            activepage={activepage}
            setactive={setactive}
            fieldName={'Chat'}
            fieldToolTip={'Chat'}
            fieldId={'chat'}
            toPage={'/dashboard/chat'}
          >
            <ChatSVG />
          </NavField>

          <NavField
            activepage={activepage}
            setactive={setactive}
            fieldName={'Tracker'}
            fieldToolTip={'Tracker'}
            fieldId={'tracker'}
            toPage={'/dashboard/tracker'}
          >
            <ClockSVG />
          </NavField>

          <OverlayTrigger
            placement="right"
            delay={{ show: 150, hide: 200 }}
            overlay={renderTooltip('HRMS')}
          >
            <a
              href={`${hrpage}/das?user=${JSON.parse(
                sessionStorage.getItem('dasid'),
              )}&password=${
                JSON.parse(sessionStorage.getItem('user')).Password
              }`}
              className={`flex w-[185px ] transi my-2 w-[185px] mt-2 gap-3 no-underline cursor-pointer py-[9px] pl-[24px] font-semibold rounded ${
                activepage === 'hrms'
                  ? 'bg-white text-[#6B6DD8] '
                  : 'text-white'
              } `}
            >
              <HRMSSVG />
              <p
                style={{
                  fontSize: '16px',
                  lineHeight: '16px',
                  letterSpacing: '1px',
                }}
                className={`transi m-0 `}
              >
                {'HRMS'}
              </p>
            </a>
          </OverlayTrigger>

          {/* <OverlayTrigger
            placement="right"
            delay={{ show: 150, hide: 200 }}
            overlay={renderTooltip('Log out')}
          >
            <div
              onClick={logout}
              className={`flex w-[185px ] transi my-2 w-[185px] mt-2 gap-3 no-underline cursor-pointer py-[9px] pl-[24px] font-semibold rounded ${
                activepage === 'setting'
                  ? 'bg-white text-[#6B6DD8] pl-[30px]  '
                  : 'text-white'
              } `}
            >
              <LogOutSVG />
              <p
                style={{
                  fontSize: '16px',
                  lineHeight: '16px',
                  letterSpacing: '0.5px',
                }}
                className={`transi m-0 `}
              >
                LOG OUT
              </p>
            </div>
          </OverlayTrigger> */}
        </section>

        {/* Middle Section */}
        <section className="mt-2 ">
          <OverlayTrigger
            placement="right"
            delay={{ show: 150, hide: 200 }}
            overlay={renderTooltip('Task List')}
          >
            <div
              onClick={() => handleNavigate('/dashboard/tasklist', 'task')}
              className={`flex w-[185px ] transi my-2 w-[203px] mt-2 gap-y-3 gap-x-[6px] no-underline cursor-pointer py-[9px] pl-2 font-semibold rounded items-center ${
                activepage === 'task'
                  ? 'bg-white text-[#6B6DD8]  '
                  : 'text-white'
              } `}
            >
              {/* <TaskBarIcon size={iconSize} /> */}
              <TaskListSVG
                active={activepage === 'task'}
                srcImg={TaskListIMG}
              />
              <p
                style={{
                  fontSize: '16px',
                  lineHeight: '16px',

                  letterSpacing: '0.5px',
                }}
                className={`transi m-0  `}
              >
                Task List
              </p>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="right"
            delay={{ show: 150, hide: 200 }}
            overlay={renderTooltip('Pending task')}
          >
            <div
              onClick={() =>
                handleNavigate('/dashboard/pendingTask', 'pending')
              }
              className={`flex w-[185px ] transi my-2 w-[203px] mt-2 gap-y-3 gap-x-[6px] no-underline cursor-pointer py-[9px] pl-2 font-semibold rounded items-center ${
                activepage === 'pending'
                  ? 'bg-white text-[#6B6DD8]   '
                  : 'text-white'
              } `}
            >
              <TaskListSVG
                active={activepage === 'pending'}
                srcImg={PendingIMG}
              />

              <p
                style={{
                  fontSize: '16px',
                  lineHeight: '16px',

                  letterSpacing: '0.5px',
                }}
                className={`transi m-0 `}
              >
                Pending Task
              </p>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="right"
            delay={{ show: 150, hide: 200 }}
            overlay={renderTooltip('Completed task')}
          >
            <div
              onClick={() =>
                handleNavigate('/dashboard/completedTask', 'completed')
              }
              className={`flex w-[185px ] transi my-2 w-[203px] mt-2 gap-y-3 gap-x-[6px] no-underline cursor-pointer py-[9px] pl-2 font-semibold rounded items-center ${
                activepage === 'completed'
                  ? 'bg-white text-[#6B6DD8]   '
                  : 'text-white'
              } `}
            >
              <TaskListSVG
                active={activepage === 'completed'}
                srcImg={ToDoListIMG}
              />
              <p
                style={{
                  fontSize: '16px',
                  lineHeight: '16px',
                  letterSpacing: '0.5px',
                }}
                className={`transi m-0 `}
              >
                Completed Task
              </p>
            </div>
          </OverlayTrigger>
        </section>

        {/* Bottom Section */}
      </div>
    </div>
  );
};

export default SideBar;
