function DataSwitcher({ approved, setApproved }) {
  return (
    <div className="flex border rounded-xl ">
      <button
        className={`${
          approved === 'approved' ? 'bg-purple-500' : 'bg-white'
        } p-2 `}
        onClick={() => setApproved('approved')}
      >
        Approved
      </button>
      <button
        className={`${
          approved === 'pending' ? 'bg-purple-500' : 'bg-white'
        } p-2`}
        onClick={() => setApproved('pending')}
      >
        Not Approved
      </button>
      <button
        className={`${
          approved === 'rejected' ? 'bg-purple-500' : 'bg-white'
        } p-2`}
        onClick={() => setApproved('rejected')}
      >
        Rejected
      </button>
    </div>
  );
}

export default DataSwitcher;
