import React, { useContext, useEffect, useState } from 'react';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Context/StateStore';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { hostName } from '../App';
import { toast } from 'react-toastify';
import DownloadButton from './DownloadButton';
import HistoryIcon from '../SVG/HistoryIcon';
import HistoryComponent from './HistoryComponent';
import { formatDateForInput } from '../utils/DateTime/timeFormatter';

const ProjectTable = props => {
  const {
    setshow,
    settask,
    data,
    setPid,
    type,
    pid,
    getallTask,
    getParticularreporting,
    approved,
    setApproved,
  } = props;
  let { status, getParticulatTaskList } = useContext(Store);
  let [showAssignedtoModal, setShowAssignedModal] = useState();
  let {
    employeeUnderUser,
    department,
    position,
    setDepartments,
    apicall,
    fetchDepartments,
    user,
    employee,
  } = useContext(Store);
  let [projectid, setprojectid] = useState();
  let [taglist, settaglist] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    employeeUnderUser();
    fetchDepartments();
  }, []);
  let [loading, setloading] = useState(false);
  console.log(data);
  let changeProjectStatus = (id, status) => {
    // console.log(id);
    setloading(id);
    axios
      .post(
        `${hostName}/api/UpdateProjectStatus/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        {
          pid: id,
          status: status,
        },
      )
      .then(response => {
        console.log(response.data);
        // alert(response.data)
        window.location.reload();
        apicall();
        setloading(-1);
        // window.location.reload()
      })
      .catch(error => {
        toast.error(error.response.data, {
          theme: 'colored',
          position: 'top-center',
          autoClose: 3000,
        });
        console.log(error);
        setloading(-1);
      });
  };
  let [temp, settemp] = useState();
  let handleSorting = e => {
    let value = e.target.value;
    if (value == '') {
      settemp(data);
    }
    if (value == 'pi') {
      let temporary = [...data].sort((a, b) => b.priority - a.priority);
      settemp(temporary);
    }
    if (value == 'du') {
      // alert('hellow')
      let temporary = [...data].sort((a, b) => {
        const dateA = new Date(a.due_date);
        const dateB = new Date(b.due_date);

        // Compare the dates
        return dateA - dateB;
      });
      settemp(temporary);
    }
  };
  let handleDueDateChange = (e, id) => {
    axios
      .patch(
        `${hostName}/api/UpdateProjectStatus/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        {
          pid: id,
          due_date: e.target.value,
        },
      )
      .then(response => {
        // window.location.reload()
        getallTask();
        toast.success('Due has been changed', {
          position: 'top-center',
          autoClose: 3000,
        });
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };
  let handleDelete = id => {
    axios
      .put(
        `${hostName}/api/UpdateProjectStatus/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        {
          pid: id,
        },
      )
      .then(response => {
        toast.error('Project has been deleted', {
          theme: 'colored',
          autoClose: 3000,
          position: 'top-center',
        });
        getallTask();
      })
      .catch(error => {
        console.log(error);
      });
  };
  let [taskassigning, setTaskAssinging] = useState({
    employee_id: null,
    project_id: null,
  });
  let assingTagList = () => {
    axios
      .post(
        `${hostName}/api/ProjectTaglist/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/${taskassigning.project_id}/`,
        taglist.map(x => x.employee_id),
      )
      .then(response => {
        console.log(response.data);
        settaglist([]);
      })
      .catch(error => {
        console.log(error);
        settaglist([]);
      });
  };
  let [assignurself, setassingurself] = useState(false);

  let handleAssignedTo = () => {
    if (taskassigning.employee_id != null && taskassigning.project_id != null) {
      setloading(true);
      console.log('TASKASSIGN', taskassigning);
      axios
        .post(
          `${hostName}/api/ProjectAssign/${JSON.parse(
            sessionStorage.getItem('dasid'),
          )}/`,
          taskassigning,
        )
        .then(response => {
          setloading(false);
          setassingurself(false);
          if (taglist.length > 0) {
            assingTagList();
          }
          toast.success('Successfully assinged the Task', {
            position: 'top-center',
            autoClose: 3000,
            theme: 'colored',
          });
          if (status.toLowerCase() == 'admin') getallTask();
          else {
            getParticularreporting();
          }

          setShowAssignedModal(false);
        })
        .catch(error => {
          console.log(error);
          setloading(false);
        });
    } else {
      toast.warning('Please assign the task to someone Before commiting', {
        theme: 'colored',
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  return (
    <div className="h-screen">
      {console.log('DATA', data)}
      {data !== undefined ? (
        <>
          {/* <select onChange={handleSorting} className='p-2 outline-none ms-auto flex bg-slate-50 rounded  my-3' name="" id="">
                            <option value="">sort</option>
                            <option value="pi">Priority H to L</option>
                            <option value="du"> Due-Date C to L </option>

                        </select> */}
          {/* Download */}
          {/* <DownloadButton data={data} name={`Project_report`} /> */}
          <div
            className="my-2 table-responsive w-full mx-auto
                         border-slate-500 h-[60vh] overflow-scroll table-bordered"
          >
            <table className="min-w-[600px] w-full ">
              <tr>
                <th>
                  <p className="px-2"> SI.No</p>
                </th>
                <th>
                  <p className="px-2"> Project Name</p>
                </th>
                <th>
                  <p className="px-2  ">Created Date </p>
                </th>
                <th>
                  <p className="px-2 ">Assigned by</p>
                </th>
                <th>
                  <p className="px-2 w100px">Due date</p>
                </th>
                <th>
                  <p className="px-2 ">Completed date </p>
                </th>
                <th>
                  <p className="px-2 ">Remarks</p>
                </th>
                <th
                  className={`${
                    status != 'employee' && type != 'own' ? '' : 'd-none'
                  } `}
                >
                  <p> Assigned to</p>
                </th>
                <th>
                  <p className="px-2 min-w-32">Task Status</p>
                </th>
                <th>
                  <p className="px-2 min-w-32">Action</p>
                </th>
              </tr>
              {console.log('data', data)}
              {data !== undefined ? (
                data
                  .filter((each, idx) => each.approved_status === approved)
                  .map((x, index) => {
                    // console.log(x);
                    return (
                      <tr
                        className={`text-sm ${
                          index % 2 == 0 ? 'bg-slate-50' : 'bg-slate-100'
                        } h-[50px] `}
                      >
                        <td className="px-2">{index + 1} </td>
                        <td
                          onClick={() => {
                            console.log(x.pid);
                            setPid(status != 'employee' ? x.id : x.pid);
                            getParticulatTaskList(
                              status != 'employee' ? x.id : x.pid,
                            );
                          }}
                          className="cursor-pointer text-blue-600 px-2 max-w-[200px] text-wrap hover:bg-slate-200"
                        >
                          {x.task_name}{' '}
                        </td>

                        {console.log('TIME', x.created_date.slice(11, 16))}
                        {console.log('TIME', x.created_date.slice(0, 10))}
                        <td className="px-2 ">{x.created_date.slice(0, 10)}</td>
                        <td className="px-2">
                          {x.created_by != null ? x.created_by : ''}
                        </td>
                        <td className="px-2">
                          {console.log('EACHDATA', x)}
                          {JSON.parse(sessionStorage.getItem('status')) !=
                            'admin' && x.due_date.slice(0, 10)}
                          {status == 'admin' && (
                            <input
                              type="datetime-local"
                              value={formatDateForInput(x.due_date)}
                              onChange={e => {
                                handleDueDateChange(e, x.id);
                              }}
                              className={` w-32 outline-none bg-transparent `}
                            />
                          )}
                        </td>
                        <td className=" ">
                          {' '}
                          {x.completed_date
                            ? x.completed_date.slice(0, 10)
                            : '---'}{' '}
                        </td>
                        <td className="px-2 max-w-[200px] text-wrap ">
                          {x.remarks != undefined ? x.remarks : 'undefined'}
                        </td>
                        {/* Chaging the assigning value */}

                        <td
                          onClick={() => {
                            setPid(
                              status == 'admin' ||
                                status == 'manager' ||
                                status == 'head'
                                ? x.id
                                : x.pid,
                            );
                            if (!x.assigned_to) {
                              setShowAssignedModal(true);
                            }
                          }}
                          className={`${
                            status != 'employee' && type != 'own'
                              ? ''
                              : 'd-none'
                          } ${
                            x.assigned_to && x.assigned_to.name
                              ? ''
                              : 'cursor-pointer bg-slate-200'
                          }`}
                        >
                          <button
                            className={` w-full outline-none h-full ${
                              x.assigned_to && x.assigned_to.name
                                ? 'd-none'
                                : ''
                            } p-3 bg-blue-200 `}
                          >
                            {' '}
                            Assigned to{' '}
                          </button>

                          {x.assigned_to && x.assigned_to.name && (
                            <button
                              onClick={() => setShowAssignedModal(true)}
                              className="w-full outline-none h-full"
                            >
                              {x.assigned_to.name}
                            </button>
                          )}
                        </td>
                        <td className="px-2">{x.status} </td>

                        {/* Add more data cells as needed */}
                        <td className="px-2 flex-wrap gap-2 flex items-center">
                          {loading != x.id && (
                            <select
                              name=""
                              id=""
                              onChange={e => {
                                console.log(x);
                                changeProjectStatus(
                                  status != 'employee' ? x.id : x.pid,
                                  e.target.value,
                                );
                              }}
                              className="outline-none border-0 bg-transparent"
                            >
                              <option
                                value={
                                  x.status.slice(0, 3) == 'com'
                                    ? 'True'
                                    : 'False'
                                }
                              >
                                {x.status.slice(0, 3) == 'com'
                                  ? 'completed'
                                  : 'pending'}
                              </option>
                              <option
                                value={
                                  x.status.slice(0, 3) == 'com'
                                    ? 'False'
                                    : 'True'
                                }
                              >
                                {x.status.slice(0, 3) == 'com'
                                  ? 'pending'
                                  : 'completed'}
                              </option>
                            </select>
                          )}
                          {loading == x.id && <p> Loading....</p>}
                          {type != 'own' && (
                            <button
                              onClick={() => {
                                setshow(true);
                                settask({
                                  task_name: x.task_name,
                                  priority: null,
                                  remarks: x.remarks,
                                  due_date: x.due_date.slice(0, 10),
                                  created_by: JSON.parse(
                                    sessionStorage.getItem('dasid'),
                                  ),
                                });
                              }}
                              className=" mx-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-c-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512" />
                              </svg>
                            </button>
                          )}
                          {status == 'admin' && (
                            <button
                              onClick={() => {
                                handleDelete(x.id);
                              }}
                              className="mx-2 text-red-500"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash3"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                              </svg>
                            </button>
                          )}
                          {console.log(x, 'log')}
                          <HistoryComponent
                            id={x.pid}
                            type="project"
                          />
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <Loading />
              )}

              {/* Add more rows as needed */}
            </table>
          </div>
          {showAssignedtoModal && (
            <Modal
              centered
              show={showAssignedtoModal}
              onHide={() => {
                setShowAssignedModal(false);
                setloading(false);
                setassingurself(false);
              }}
            >
              <Modal.Header closeButton>Change the Task To others</Modal.Header>
              <Modal.Body>
                <div>
                  {/* {loading && <Spinner animation="border" className='mx-4' role="status" />} */}

                  <div className="my-3">
                    <section className="flex justify-between my-4">
                      <div>
                        Assigned to:
                        {
                          <select
                            disabled={assignurself}
                            onChange={e => {
                              setTaskAssinging({
                                employee_id: e.target.value,
                                project_id: pid,
                              });
                            }}
                            className="outline-none p-2 bg-transparent bg-slate-50"
                            name=""
                            id=""
                          >
                            <option value="">Select</option>

                            {/* {user && <option value={user.pk}>{user.name} </option>} */}
                            {employee &&
                              employee.map(x => {
                                return (
                                  <option value={x.pk}>
                                    {x.name} ({x.position.position}){' '}
                                  </option>
                                );
                              })}
                          </select>
                        }
                      </div>

                      <div className="flex gap-2 items-center ">
                        <input
                          type="checkbox"
                          onClick={() => {
                            setassingurself(!assignurself);
                            setTaskAssinging({
                              employee_id: JSON.parse(
                                sessionStorage.getItem('dasid'),
                              ),
                              project_id: pid,
                            });
                          }}
                          id="ass"
                          checked={assignurself}
                          className="p-2 px-3 rounded bg-slate-500 text-white"
                        />
                        <label htmlFor="ass"> Assign Yourself</label>
                      </div>
                    </section>

                    <div>
                      Tag List :
                      <select
                        onChange={e => {
                          if (e.target.value != '') {
                            let value = JSON.parse(e.target.value);
                            if (
                              taglist &&
                              taglist.some(x => x.pk == value.pk)
                            ) {
                              settaglist(prev =>
                                prev.filter(y => y.pk != value.pk),
                              );
                            } else {
                              settaglist(prev => [...prev, value]);
                            }
                          }
                        }}
                        className="outline-none "
                        name=""
                        id=""
                      >
                        <option value="">Select</option>
                        {employee &&
                          employee.map(x => {
                            // console.log(x);
                            return (
                              <option value={JSON.stringify(x)}>
                                {x.name} ({x.position.position}){' '}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="flex flex-wrap gap-3">
                      {taglist &&
                        taglist.map(z => {
                          console.log(z);
                          return (
                            <p className="m-0 flex gap-2 p-1 bg-slate-50 rounded-full px-2">
                              {' '}
                              {z.name}
                              <button
                                onClick={() => {
                                  settaglist(prev =>
                                    prev.filter(x => x.pk != z.pk),
                                  );
                                }}
                                className="text-red-500 w-fit h-fit rounded-full p-1 border-1 border-red-500 "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  fill="currentColor"
                                  class="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                </svg>
                              </button>
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  disabled={loading}
                  onClick={handleAssignedTo}
                  className="p-2 px-3 rounded bg-slate-500 mx-auto text-white "
                >
                  {loading ? 'Loading...' : 'Assign'}{' '}
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProjectTable;
