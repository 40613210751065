import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { hostName } from '../../App';
import { Store } from '../../Context/StateStore';

const EmployeeDetailsUpdate = ({ data, fun }) => {
  let { employeeUnderUser, employee } = useContext(Store);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const fetchDepartments = () => {
    axios
      .get(`${hostName}/api/DisplayDepartments/`)
      .then(response => {
        setDepartments(response.data.Departments);
        setPositions(response.data.Positions);
      })
      .catch(error => {
        console.error('Error fetching departments:', error);
      });
  };
  useEffect(() => {
    employeeUnderUser();
    fetchDepartments();
    console.log(employee);
  }, []);
  let [empData, setEmpData] = useState({
    name: '',
    email: '',
    password: '',
    department: null,
    position: null,
    reporting_to: null,
    status: '',
  });
  let handleChange = e => {
    let { name, value } = e.target;
    setEmpData(prev => ({
      ...prev,
      [name]: value,
    }));
    console.log({
      [name]: value,
    });
    axios
      .patch(`${hostName}/api/UpdateProfile/${data.employee_id}/`, {
        [name]: value,
      })
      .then(response => {
        // apicall()
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
        alert('error');
      });
  };

  useEffect(() => {
    if (data) {
      setEmpData({
        ...data,
        reporting_to: data.reporting_to && data.reporting_to.pk,
        department: data.department.id,
        position: data.position.id,
      });
      console.log(data);
    }
  }, [data]);
  return (
    <div className="rounded bg-white p-3 ">
      <h6 className="text-xl">Employee Details</h6>
      {empData && (
        <>
          <div className="my-3">
            <label className="w-40 "> Name : </label>
            <input
              disabled
              type="text"
              onChange={handleChange}
              name="name"
              value={empData.name}
              className="outline-none mx-2 px-2 border-bottom  "
            />
          </div>
          <div className="my-3">
            <label className="w-40 "> Email : </label>
            <input
              disabled
              type="text"
              onChange={handleChange}
              name="email"
              value={empData.email}
              className="outline-none mx-2 px-2 border-bottom  "
            />
          </div>
          <div className="">
            <label
              htmlFor="position"
              className=" w-40 "
            >
              Reporting to :{' '}
            </label>
            <select
              disabled
              className="my-2 mx-2  outline-none w-[300px] text-slate-950 rounded p-2 border-slate-400 border-1"
              id=""
              name="reporting_to"
              value={empData.reporting_to}
              onChange={handleChange}
            >
              <option value="">Select Reporting to</option>
              {employee &&
                employee.map(pos => (
                  <option
                    key={pos.name}
                    value={pos.pk}
                  >
                    {pos.name} ({pos.department.department_name} , {pos.status})
                  </option>
                ))}
            </select>
          </div>
          <div className="my-3">
            <label
              htmlFor=""
              className="w-40 "
            >
              {' '}
              Department :
            </label>
            <select
              disabled
              className="my-2 mx-2 outline-none text-slate-950 rounded p-2 border-slate-400 border-1"
              id="department"
              name="department"
              value={empData.department}
              onChange={handleChange}
            >
              {departments.map(dept => (
                <option
                  key={dept.id}
                  value={dept.id}
                >
                  {dept.department_name}
                </option>
              ))}
            </select>
          </div>

          <div className="">
            <label
              htmlFor=""
              className="w-40 "
            >
              {' '}
              Position :
            </label>

            <select
              onChange={handleChange}
              disabled
              value={empData.status}
              className="my-2 mx-2 outline-none text-slate-950 rounded p-2 border-slate-400 border-1"
              name="status"
              id=""
            >
              <option value="admin">Admin</option>
              <option value="head">Head</option>
              <option value="manager">Manager</option>
              <option value="team_leader">Team Lead</option>
              <option value="employee">Executive</option>
            </select>
          </div>
          <div className="w-full ">
            <label
              htmlFor="position"
              className="w-40 "
            >
              Role:{' '}
            </label>
            <select
              disabled
              className="my-2 mx-2 outline-none text-slate-950 rounded p-2 border-slate-400 border-1"
              id="position"
              name="position"
              value={empData.position}
              onChange={handleChange}
            >
              {positions.map(pos => (
                <option
                  key={pos.id}
                  value={pos.id}
                >
                  {pos.position}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
};

export default EmployeeDetailsUpdate;
