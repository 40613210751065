import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { hostName } from '../../App';
import { tr } from 'date-fns/locale';

const ViewTaskData = ({ show, setshow, subTask, setSubTask }) => {
  let [data, setData] = useState();
  let [loading, setLoading] = useState(false);

  const user = sessionStorage.getItem('user');
  const empID = JSON.parse(user);
  // console.log('MMUIZID', empID.EmployeeId);

  let getData = () => {
    setLoading(true);
    axios
      .get(`${hostName}/api/ProjectDetails/${show}`)
      .then(response => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  let getData2 = () => {
    setLoading(true);
    axios
      .get(`${hostName}/api/SubTaskDetails/${subTask}`)
      .then(response => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (show) getData();
    if (subTask) getData2();
  }, [show, subTask]);

  const handleApproval = () => {
    axios
      .patch(`${hostName}/api/UpdateProjectStatus/${empID.EmployeeId}/`, {
        approved_status: 'approved',
        pid: show,
      })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };
  const handleRejection = () => {
    axios
      .patch(`${hostName}/api/UpdateProjectStatus/${empID.EmployeeId}/`, {
        approved_status: 'rejected',
        pid: show,
      })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };
  // console.log('MUIZZZ', show);

  return (
    <div>
      <Modal
        className=" "
        centered
        show={show || subTask}
        onHide={() => {
          setshow(false);
          setSubTask(false);
        }}
      >
        <Modal.Header closeButton>Task Details</Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="min-h-[60vh] flex ">
              <Spinner className="m-auto " />
            </div>
          ) : (
            data && (
              <main className="max-h-[60vh] overflow-y-scroll scrollbar ">
                <div className="flex justify-between">
                  <h4> Task Details </h4>
                  <div className="">
                    {(data.approved_status === 'pending' ||
                      data.approved_status === 'rejected') && (
                      <button
                        className="bg-green-500 rounded px-2"
                        onClick={handleApproval}
                      >
                        APPROVE
                      </button>
                    )}
                    {(data.approved_status === 'pending' ||
                      data.approved_status === 'rejected') && (
                      <button
                        className="bg-red-500 rounded px-2 ml-3"
                        onClick={handleRejection}
                      >
                        REJECT
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex  ">
                  <p className="w-40">Task Name : </p>
                  {data.task_name}
                  {console.log('MUIZ', data)}
                </div>
                <div className="flex ">
                  <p className="w-40">Assigned To : </p>
                  {show && data?.assigned_to
                    ? data?.assigned_to.name
                    : data?.projectdetails?.assigned_to &&
                      data?.projectdetails?.assigned_to?.name}
                </div>
                <div className="flex  ">
                  <p className="w-40">Assigned By : </p>
                  {show ? data?.created_by : data.created_by.name}
                </div>
                <div className="flex  ">
                  <p className="w-40">Created Date: </p>
                  {data.created_date && data.created_date.slice(0, 10)}
                </div>
                <div className="flex  ">
                  <p className="w-40">Due Date: </p>
                  {data.due_date && data.due_date.slice(0, 10)}
                </div>
                <div className="flex  ">
                  <p className="w-40">Last Submitted Date: </p>
                  {data.completed_date && data.completed_date.slice(0, 10)}
                </div>
                <div className="flex  ">
                  <p className="w-40">Status : </p>
                  {data.status}
                </div>
                <div className="flex  ">
                  <p className="w-40">Remarks : </p>
                  {data.remarks}
                </div>
                {data && data.subtasks && (
                  <>
                    <h4>Subtasks for the Task </h4>
                    <div className="table-responsive ">
                      <table className="tablebg w-full ">
                        <tr>
                          <th>SI No </th>
                          <th>Task Name </th>
                          <th>Remark </th>
                          <th>Created by </th>
                          <th>Created Date </th>
                          <th>Due date </th>
                          <th>Completed Date </th>
                          <th>Status </th>
                        </tr>
                        {data.subtasks.map((obj, index) => (
                          <tr>
                            <td> {index + 1} </td>
                            <td> {obj.task_name} </td>
                            <td> {obj.remarks} </td>
                            <td> {obj.created_by.name} </td>
                            <td>
                              {' '}
                              {obj.created_date &&
                                obj.created_date.slice(0, 10)}{' '}
                            </td>
                            <td>
                              {' '}
                              {obj.due_date && obj.due_date.slice(0, 10)}{' '}
                            </td>
                            <td>
                              {' '}
                              {obj.completed_date &&
                                obj.completed_date.slice(0, 10)}{' '}
                            </td>
                            <td> {obj.status} </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </>
                )}
              </main>
            )
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewTaskData;
