function HRMSSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 9H6.75V4.5H2.25V9ZM7.5 10.5H1.5C1.086 10.5 0.75 10.1648 0.75 9.75V3.75C0.75 3.33525 1.086 3 1.5 3H7.5C7.914 3 8.25 3.33525 8.25 3.75V9.75C8.25 10.1648 7.914 10.5 7.5 10.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5 4.5H12C11.586 4.5 11.25 4.16475 11.25 3.75C11.25 3.33525 11.586 3 12 3H22.5C22.9148 3 23.25 3.33525 23.25 3.75C23.25 4.16475 22.9148 4.5 22.5 4.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5 9H12C11.586 9 11.25 8.66475 11.25 8.25C11.25 7.83525 11.586 7.5 12 7.5H19.5C19.9148 7.5 20.25 7.83525 20.25 8.25C20.25 8.66475 19.9148 9 19.5 9Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 19.5H6.75V15H2.25V19.5ZM7.5 21H1.5C1.086 21 0.75 20.6648 0.75 20.25V14.25C0.75 13.8353 1.086 13.5 1.5 13.5H7.5C7.914 13.5 8.25 13.8353 8.25 14.25V20.25C8.25 20.6648 7.914 21 7.5 21Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5 15H12C11.586 15 11.25 14.6647 11.25 14.25C11.25 13.8353 11.586 13.5 12 13.5H22.5C22.9148 13.5 23.25 13.8353 23.25 14.25C23.25 14.6647 22.9148 15 22.5 15Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5 19.5H12C11.586 19.5 11.25 19.1647 11.25 18.75C11.25 18.3353 11.586 18 12 18H19.5C19.9148 18 20.25 18.3353 20.25 18.75C20.25 19.1647 19.9148 19.5 19.5 19.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default HRMSSVG;
