function CalenderSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.75 21C21.75 21.4132 21.4132 21.75 21 21.75H3C2.58675 21.75 2.25 21.4132 2.25 21V8.25H21.75V21ZM2.25 4.5C2.25 4.08675 2.58675 3.75 3 3.75H4.5V4.5C4.5 4.914 4.836 5.25 5.25 5.25H8.25C8.664 5.25 9 4.914 9 4.5V3.75H15V4.5C15 4.914 15.336 5.25 15.75 5.25H18.75C19.164 5.25 19.5 4.914 19.5 4.5V3.75H21C21.4132 3.75 21.75 4.08675 21.75 4.5V6.75H2.25V4.5ZM6 3.75H7.5V2.25H6V3.75ZM16.5 3.75H18V2.25H16.5V3.75ZM21 2.25H19.5V1.5C19.5 1.086 19.164 0.75 18.75 0.75H15.75C15.336 0.75 15 1.086 15 1.5V2.25H9V1.5C9 1.086 8.664 0.75 8.25 0.75H5.25C4.836 0.75 4.5 1.086 4.5 1.5V2.25H3C1.7595 2.25 0.75 3.2595 0.75 4.5V21C0.75 22.2405 1.7595 23.25 3 23.25H21C22.2405 23.25 23.25 22.2405 23.25 21V4.5C23.25 3.2595 22.2405 2.25 21 2.25Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0471 14.9999C11.3719 14.9999 11.6599 14.7907 11.7604 14.4817L12.0004 13.7444L12.2396 14.4817C12.3401 14.7907 12.6281 14.9999 12.9529 14.9999H13.7276L13.1006 15.4552C12.8381 15.6464 12.7279 15.9847 12.8284 16.2944L13.0676 17.0309L12.4406 16.5757C12.1804 16.3859 11.8234 16.3837 11.5594 16.5757L10.9324 17.0309L11.1716 16.2944C11.2721 15.9847 11.1619 15.6464 10.8994 15.4552L10.2724 14.9999H11.0471ZM9.57715 16.3484L8.79265 18.7627C8.69215 19.0717 8.80165 19.4099 9.0649 19.6012C9.32815 19.7924 9.68365 19.7924 9.9469 19.6012L12.0004 18.1094L14.0531 19.6012C14.3141 19.7909 14.6711 19.7932 14.9351 19.6012C15.1984 19.4099 15.3079 19.0717 15.2074 18.7627L14.4229 16.3484L16.4771 14.8567C16.7396 14.6654 16.8499 14.3272 16.7494 14.0182C16.6489 13.7092 16.3609 13.4999 16.0361 13.4999H13.4981L12.7136 11.0857C12.6131 10.7767 12.3251 10.5674 12.0004 10.5674C11.6749 10.5674 11.3869 10.7767 11.2864 11.0857L10.5019 13.4999H7.9639C7.63915 13.4999 7.35115 13.7092 7.25065 14.0182C7.15015 14.3272 7.2604 14.6654 7.5229 14.8567L9.57715 16.3484Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalenderSVG;
