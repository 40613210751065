import axios from 'axios';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { hostName } from '../../../App';
import { IoMdTrash } from 'react-icons/io';

function DailyMemo({ date }) {
  const formattedDateForGET = dayjs(date).format('YYYY-MM-DD');
  const formattedDateForPOST = new Date(date);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const empID = user.EmployeeId;

  const [memos, setMemos] = useState([]);
  const [note, setNote] = useState('');

  const fetchMemos = () => {
    const API = `${hostName}/api/DailyCalendarView/?emp_id=${empID}&date=${formattedDateForGET}`;
    axios
      .get(API)
      .then(res => {
        console.log('MEMOS', API, res.data);
        setMemos(res.data);
      })
      .catch(err => console.error(err));
  };

  const postMemos = () => {
    if (!note.trim()) return;
    axios
      .post(`${hostName}/api/DailyCalendarView/`, {
        date_time: formattedDateForPOST,
        emp_id: empID,
        note,
      })
      .then(() => {
        setNote(''); // Clear input
        fetchMemos(); // Refresh memos
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    fetchMemos();
  }, [date]);

  const timeFormatter = time => dayjs(time).format('h:mm A');

  const handleDelete = id => {
    axios
      .delete(`${hostName}/api/DailyCalendarView/${id}`)
      .then(res => {
        console.log(res.data);
        fetchMemos();
      })
      .catch(err => console.log(err));
  };

  return (
    <div className=" rounded-b-2xl pb-4 pt-2 px-2 flex flex-col h-[42vh]">
      <div className="flex items-center gap-x-2 mb-2">
        <input
          type="text"
          className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Add a note..."
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        <button
          onClick={postMemos}
          className="bg-[#6B6DD8] text-white px-3 py-2 rounded-lg hover:bg-[#5859c6] transition"
        >
          +
        </button>
      </div>

      <div className="overflow-y-scroll space-y-1 mt-2 scrollbar2">
        {memos?.reverse().map((item, idx) => (
          <div
            key={idx}
            className={`flex items-center justify-between border-b px-3 py-2 ${
              idx === memos.length - 1 ? '' : 'border-gray-300'
            }`}
          >
            <p className="text-gray-700 text-sm truncate flex-1">{item.note}</p>
            <div className="flex items-center gap-2 ml-3">
              <p className="text-gray-500 text-xs whitespace-nowrap">
                {timeFormatter(item.date_time)}
              </p>
              <button
                className="text-red-500 hover:text-red-600 transition-transform transform hover:scale-110"
                onClick={() => handleDelete(item.id)}
              >
                <IoMdTrash className="text-base" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DailyMemo;
