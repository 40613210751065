import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginProd() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      navigate('/developer/login');
    } else {
      window.location.href = 'https://hrm.meridahr.com/';
    }
  }, []);

  return null;
}

export default LoginProd;
