import React, { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { hostName } from '../App';
import { Store } from '../Context/StateStore';
import StickyCards from './Dashboard/StickyNotes/StickyCards';
import { FaRegSquarePlus } from 'react-icons/fa6';

const StickyNotes1 = () => {
  const { user } = useContext(Store);

  const [message, setMessage] = useState([]);
  const newNoteRef = useRef(null); // Ref for new note
  const userID = JSON.parse(sessionStorage.getItem('dasid'));

  const handleChange = (e, id) => {
    const { value } = e.target;
    const findIndex = message.findIndex(obj => obj.id === id);
    const arry = [...message];
    arry[findIndex].note = value;
    setMessage(arry);
    axios
      .patch(`${hostName}/api2/NotesViewSet/${userID}/`, {
        id,
        note: value,
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDelete = id => {
    axios
      .delete(`${hostName}/api2/NotesViewSet/${id}`)
      .then(response => {
        console.log(response.data);
        getMessage();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getMessage = () => {
    axios
      .get(`${hostName}/api2/NotesViewSet/${userID}/`)
      .then(response => {
        setMessage(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSave = () => {
    if (user) {
      axios
        .post(`${hostName}/api2/NotesViewSet/${userID}/`, {
          note: '',
        })
        .then(response => {
          console.log(response.data);
          getMessage();
          scrollToNewNote(); // Scroll after saving
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const scrollToNewNote = () => {
    setTimeout(() => {
      if (newNoteRef.current) {
        newNoteRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }, 100); // Slight delay to ensure DOM updates
  };

  useEffect(() => {
    if (user) {
      getMessage();
    }
  }, [user]);

  const clearAll = () => {
    axios
      .post(
        `${hostName}/api2/DeleteAllNotes/${userID}/`,
        message.map(obj => obj.id),
      )
      .then(response => {
        getMessage();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="w-full h-max rounded-2xl bg-white ">
      <div className="w-full flex justify-between">
        <h1
          style={{ fontSize: '20px', lineHeight: 'normal' }}
          className="text-[#27104E] p-2 m-2"
        >
          Sticky Notes
        </h1>

        <div className="gap-x-3 mx-3 flex items-center">
          <button
            onClick={handleSave}
            className="p-2 px-3 h-fit bg-blue-500 text-white rounded"
          >
            <FaRegSquarePlus className="text-2xl" />
          </button>

          <button
            onClick={clearAll}
            className="p-2 px-3 rounded bg-slate-500 text-white"
          >
            <span>Clear All</span>
          </button>
        </div>
      </div>

      {/* Scrollable Container */}
      <div className="h-[70vh] flex flex-col gap-4 px-2 mx-2 overflow-x-auto scrollbar">
        {/* First Row */}
        <div className="flex flex-nowrap gap-4">
          {message
            .filter((_, index) => index % 2 === 0) // First row: even-indexed notes
            .map((obj, index) => (
              <div
                key={obj.id}
                ref={
                  index === Math.floor(message.length / 2) ? newNoteRef : null
                } // Assign ref to the first row's last item
                className="shrink-0"
              >
                <StickyCards
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                  index={index}
                  obj={obj}
                />
              </div>
            ))}
        </div>

        {/* Second Row */}
        <div className="flex flex-nowrap gap-4">
          {message
            .filter((_, index) => index % 2 !== 0) // Second row: odd-indexed notes
            .map((obj, index) => (
              <div
                key={obj.id}
                ref={
                  index === Math.ceil(message.length / 2) - 1
                    ? newNoteRef
                    : null
                } // Assign ref to the second row's last item
                className="shrink-0"
              >
                <StickyCards
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                  index={index}
                  obj={obj}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StickyNotes1;
