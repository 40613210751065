import React from 'react';
import LoginProd from './LoginProd/LoginProd';

const Protect = ({ Child }) => {
  let user = JSON.parse(sessionStorage.getItem('dasid'));

  return (
    <div>{user != null && user != undefined ? <Child /> : <LoginProd />}</div>
  );
};

export default Protect;
