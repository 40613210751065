import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Context/StateStore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmployeeDrop = props => {
  let { id, uid, setid, filterfun, selectedid } = props;
  let {
    employeeUnderUser,
    department,
    position,
    setDepartments,
    fetchDepartments,
    employee,
  } = useContext(Store);

  let navigate = useNavigate();
  useEffect(() => {
    employeeUnderUser();
    fetchDepartments();
    if (selectedid) {
      setid(selectedid);
    }
    let sid = sessionStorage.getItem('selectedEmp');
    if (sid != 'all') {
      setid(sid);
    }
  }, [selectedid]);
  return (
    <div>
      {/* Select the Employee */}
      <select
        value={uid && uid}
        onChange={e => {
          filterfun(e.target.value);
          sessionStorage.setItem('selectedEmp', e.target.value);
          setid(e.target.value);
        }}
        name=""
        className="outline-none rounded ms-auto flex p-2 border-1"
        id=""
      >
        <option value="all">Select Employee</option>
        {employee != undefined &&
          employee.map(x => {
            return (
              <option value={x.employee_id}>
                {x.name} ({x.position.position}){' '}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default EmployeeDrop;
