import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../../Context/StateStore';
import PlusIcon from '../../SVG/PlusIcon';
import SearchIcon from '../../SVG/SearchIcon';
import axios from 'axios';
import { hostName } from '../../App';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const DepartmentList = () => {
  let { setactive } = useContext(Store);
  let status = JSON.parse(sessionStorage.getItem('status'));

  let navigate = useNavigate();
  let [obj, setobj] = useState({
    position: '',
    department_name: '',
  });
  let handleChange = e => {
    setobj(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  let addDepartment = () => {
    console.log(obj);

    axios
      .post(`${hostName}/api/DisplayDepartments/`, {
        department_name: obj.department_name,
        position: obj.position,
      })
      .then(response => {
        console.log(response.data);
        fetchDepartments();
        setobj({
          position: '',
          department_name: '',
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  const handleChangedept = e => {
    let { name, value } = e.target;
    setEditobj(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  let handleUpdate = () => {
    if (editobj.type == 'dept') {
      axios
        .put(`${hostName}/api/DisplayDepartments/`, editobj)
        .then(response => {
          console.log(response.data);
          setEditing(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
    fetchDepartments();
  };
  useEffect(() => {
    setactive('employee');
  }, []);
  let [showDepartment, setshowDepartment] = useState();
  let [changethings, setchangethings] = useState();
  let [department, setDepartments] = useState([]);
  let [filteredDept, setFilterDept] = useState([]);
  let [filterword, setFilterWord] = useState();
  let [editingDep, setEditing] = useState();
  const [editobj, setEditobj] = useState({
    id: null,
    name: null,
    type: null,
  });
  const fetchDepartments = () => {
    axios
      .get(`${hostName}/api/DisplayDepartments/`)
      .then(response => {
        console.log(response.data.Departments);
        setDepartments(response.data.Departments);
        setFilterDept(response.data.Departments);
      })
      .catch(error => {
        console.error('Error fetching departments:', error);
      });
  };
  useEffect(() => {
    fetchDepartments();
  }, []);

  let getfilterdept = () => {
    if (filterword != '') {
      let filterData = department.filter(
        obj =>
          obj.department_name.toLowerCase().indexOf(filterword.toLowerCase()) !=
          -1,
      );
      setFilterDept(filterData);
    } else {
      setFilterDept(department);
    }
  };
  return (
    <div className="my-3 container">
      <main className="flex my-3  flex-wrap justify-between">
        <h5>Our Departments </h5>
        <article className="flex items-center gap-3">
          <div className=" border-2 border-violet-300 rounded  p-1 flex items-center ">
            <input
              onKeyDown={e => {
                if (e.key == 'Enter') {
                  getfilterdept();
                }
              }}
              value={filterword}
              onChange={e => setFilterWord(e.target.value)}
              type="text"
              className="p-1 bg-transparent outline-none"
              placeholder="Search "
            />
            <span
              className=""
              onClick={getfilterdept}
            >
              {' '}
              <SearchIcon />{' '}
            </span>
          </div>
          {/* {status == 'admin' &&
            <button onClick={() => { setshowDepartment(true); setchangethings('department') }} className='bg-white gap-2 items-center rounded-full p-1 px-2 flex '>
              <span className='text-white bg-violet-600 p-1 rounded-full'>
                <PlusIcon size={10} />
              </span>
              Add new
            </button>} */}
        </article>
      </main>
      <main className="flex flex-wrap gap-3 justify-around sm:justify-between px-sm-3">
        {console.log(filteredDept)}
        {filteredDept &&
          filteredDept.map(obj => {
            return (
              <article className="w-[18rem] bg-white p-2 rounded ">
                <img
                  src={require('../../Assests/deptimage.png')}
                  alt="Department "
                />
                <h6 className="text-center my-2"> {obj.department_name}</h6>
                <button
                  onClick={() => navigate(`/dashboard/department/${obj.id}`)}
                  className="p-2 px-3 bg-violet-600 text-white rounded-full mx-auto flex text-xs"
                >
                  View Members
                </button>
              </article>
            );
          })}
        <article className="w-[18rem] bg-white p-2 rounded ">
          <img
            src={require('../../Assests/deptimage.png')}
            alt="Department "
          />
          <h6 className="text-center my-2"> Blocked Users</h6>
          <button
            onClick={() => navigate(`/dashboard/department/blocked`)}
            className="p-2 px-3 bg-red-600 text-white rounded-full mx-auto flex text-xs"
          >
            view members
          </button>
        </article>
      </main>
      {showDepartment && (
        <Modal
          centered
          show={showDepartment}
          onHide={() => setshowDepartment(false)}
        >
          <Modal.Header closeButton>
            Add the New {changethings == 'department' ? 'Department' : 'Role'}
          </Modal.Header>
          <Modal.Body>
            Existed {changethings == 'department' ? 'Department' : 'Role'}
            <div className={`flex flex-wrap justify-around `}>
              {department != undefined &&
                changethings == 'department' &&
                department.map(x => {
                  return (
                    <div
                      onClick={() => {
                        setEditing(true);
                        setEditobj({
                          id: x.id,
                          name: x.department_name,
                          type: 'dept',
                        });
                        setshowDepartment(false);
                      }}
                      className="bg-slate-100 px-2 m-1 p-1 rounded-xl"
                    >
                      {x.department_name}
                    </div>
                  );
                })}
            </div>
            <hr />
            <div
              className={`my-2 text-center ${
                changethings == 'department' ? '' : 'd-none'
              } `}
            >
              Enter the Department name :
              <input
                type="text"
                name="department_name"
                value={obj.department_name}
                className="border-1 mx-1 outline-none my-2 p-2"
                placeholder="Technical Team"
                onChange={handleChange}
              />
            </div>
            <div
              className={`my-2 text-center ${
                changethings == 'department' ? 'd-none' : ''
              } `}
            >
              Enter the Role name :
              <input
                type="text"
                name="position"
                value={obj.position}
                className="border-1 mx-1 outline-none my-2 p-2"
                placeholder="UI/Ux developer"
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <button
            onClick={addDepartment}
            className="ms-auto p-2 px-3 bg-green-600 rounded text-white m-3 w-fit"
          >
            Add {changethings == 'department' ? 'Department' : 'Position'}
          </button>
        </Modal>
      )}
      {editingDep && (
        <Modal
          centered
          onHide={() => setEditing(false)}
          show={editingDep}
        >
          <Modal.Body>
            <div>
              Change the Name :
              <input
                type="text"
                value={editobj.name}
                name="name"
                onChange={handleChangedept}
                className="outline-none rounded p-2 px-3 border-1 "
              />
              <button
                onClick={handleUpdate}
                className="p-2 px-3 bg-slate-500 text-white "
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default DepartmentList;
